/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
*https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=
*http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Profile from "views/examples/Profile.jsx";


import Task from "views/examples/Task.jsx";
import Transaction from "views/examples/Transaction.jsx";
import annualreturn from "views/examples/incorporation.jsx";
import Reports from "views/examples/Reports.jsx";
import Appointment from "views/examples/Appointment.jsx";
import MasterFile from "views/examples/Masterfile.jsx";
import registerincorporation from "views/examples/registerincorporation2.jsx";

import Register from "views/examples/Register.jsx";
import Login from "views/examples/Login.jsx";
import Tables from "views/examples/Tables.jsx";
import Quotation from "views/examples/Quotation.jsx";
import AddNewQuotation from "views/examples/AddNewQuotation.jsx";
//import AddNewROT from "views/examples/AddNewROT.jsx";
import Forget from "views/examples/Forgetpassword.jsx";
import Resolution from "views/examples/Resolution.jsx";
import MBRS from "views/examples/MBRS.jsx";
import Notification from "views/examples/Notification.jsx";
var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-blue",
    component: Dashboard,
    layout: "/admin"
  },
 
  {
    path: "/task",
    name: "Task",
    icon: "ni ni-bullet-list-67 text-blue",
    component: Task,
    layout: "/admin"
  },
  {
    path: "/notification",
    name: "Notification",
    icon: "ni ni-bell-55 text-blue",
    component: Notification,
    layout: "/admin"
  },
  {
    path: "/transaction",
    name: "Transaction",
    icon: "ni ni-money-coins text-blue",
    component: Transaction,
    layout: "/admin"
  },
  {
    path: "/incorporation",
    name: "Incorporation",
    icon: "ni ni-books text-blue",
    component: annualreturn,
    layout: "/admin"
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "ni ni-chart-bar-32 text-blue",
    component: Reports,
    layout: "/admin"
  },
  {
    path: "/resolution",
    name: "Resolution",
    icon: "ni ni-collection text-blue",
    component: Resolution,
    layout: "/admin"
  },
  // {
  //   path: "/appointment",
  //   name: "Appointment",
  //   icon: "ni ni-calendar-grid-58 text-blue",
  //   component: Appointment,
  //   layout: "/admin"
  // },

  {
    path: "/MBRS",
    name: "MBRS",
    icon: "ni ni-folder-17 text-blue",
    component: MBRS,
    layout: "/admin"
  },
  {
    path: "/masterfile",
    name: "MasterFile",
    icon: "ni ni-settings text-blue",
    component: MasterFile,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/addnewquotation",
    name: "Add New Quotation",
    icon: "ni ni-money-coins text-blue",
    component: AddNewQuotation,
    layout: "/admin"
  },
  {
    path: "/registerincorporation",
    name: "Register Incorporation",
    icon: "ni ni-money-coins text-blue",
    component: registerincorporation,
    layout: "/admin"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/forgetpassword",
    name: "Forget password",
    icon: "ni ni-single-02 text-yellow",
    component: Forget,
    layout: "/auth"
  },
 
];
export default routes;
