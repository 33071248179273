/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
* npm run build
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import classnames from "classnames";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
//import Moment from 'moment';
// reactstrap components
import { Card,
  Alert,
  Modal,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Form,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
  FormFeedback,

  Button,} from "reactstrap";
  import ReactToPrint from 'react-to-print';
  import { Link } from 'react-router-dom';
// core components
import ReactDatetime from "react-datetime";
import Header from "components/Headers/Header.jsx";
import Footer from "components/Footers/AdminFooter.jsx";
import { textChangeRangeIsUnchanged } from "typescript";
import axios ,{post} from 'axios'; 
import Moment from 'moment';

class Incorporation extends React.Component {

  constructor(props){
    super(props);
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
    }
  }

  state = {    
    tabs: 1,
    tabsimportexport:1,    
    attachmentlist:[],
    rotpendinglist:[],
    searchtextvalue:'',
    yearofmbrs:2022,
    modalincorporation:false,
    fileuploadnumber2:true,
    fileuploadnumber3:true,
    fileuploadnumber4:true,
    fileuploadnumber5:true,
    fileuploadnumber6:true,
    fileuploadnumber7:true,
    fileuploadnumber8:true,
    fileuploadnumber9:true,
    fileuploadnumber10:true,
    linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"http://crm.mewahtrans.com.my/api/img/profile_img/",
  };

  


  componentDidMount(){
   // this.getpendingorderlistapi("Import");
   document.addEventListener('keyup', (e) => {
    if (e.keyCode === 27){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.cancelledsearchbuttonclick()
      }
     
    }else if(e.keyCode === 13){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.searchfunctionstart()
      }
      
    }
    },false);
    this.setState({searchtextvalue:""})
    this.setState({buttoncolor2:"primary"})
 //  this.setdatedefaultincomplete();
   // this.getcreatedbywho();
   // this.getorderlistapi("Import");
    this.setState({tabsimportexport:1})
    this.setState({attachmentsetID:Moment().unix()}) ;   
  }

  cancelledsearchbuttonclick = () =>{
    if(this.state.tabs == 1){
     this.setState({searchtextvalue:""})
     this.setState({currentcompletedpagenumber:1})
     if(this.state.tabsimportexport == 1){
      this.getpendingorderlistapi("Import");
     }else{
      this.getpendingorderlistapi("Export");
     }
     
    }else if( this.state.tabs ==2){
     this.setState({searchtextvalue:""})
     if(this.state.tabsimportexport == 1){
      this.getorderlistapi("Import");
     }else{
      this.getorderlistapi("Export");
     }
    }else if( this.state.tabs ==3){
      this.setState({searchtextvalue:""})
      if(this.state.tabsimportexport == 1){
        this.getcompletedorderapiwithdaterange("Import");
       }else{
        this.getcompletedorderapiwithdaterange("Export");
       }
     }      
  }

  nextpaginationfunction = (e) =>{
    if(this.state.completelist.length/100 <= this.state.currentcompletedpagenumber){
      
    }else{
     this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber +1})
    }
  }
 
  previouspaginationfunction = (e) =>{
   if(this.state.currentcompletedpagenumber== 1){
     
   }else{
    this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber - 1})
   }
  }

  renderTablePagination() {
         
      { if( this.state.completelist.length/100 < 1){
        return( null )
      }else if(this.state.completelist.length/100 >= 1 && this.state.completelist.length/100 < 2 ){
       return (
         
            
          <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                           <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                            <PaginationLink href="#pablo" onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>      
                 <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
                  
                 )
      }else if(this.state.completelist.length/100 >= 2 && this.state.completelist.length/100 < 3 ){
       return (
          
               
             <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>      
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               
             
            


                 )
      }else if(this.state.completelist.length/100 >= 3 && this.state.completelist.length/100 < 4 ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                       <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber ==2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>          
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      }else if(this.state.completelist.length/100 >= 4 && this.state.completelist.length/100 < 5 ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>   
                          <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                              5
                            </PaginationLink>
                          </PaginationItem>           
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      }else if(this.state.completelist.length/100 >= 5  ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                    <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>   
                          <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                              5
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 6 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:6})}>
                              ...
                            </PaginationLink>
                          </PaginationItem>           
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      } 
      
     }
  }


  handleKeyPress = (event) => {
    if(event.key === 'Escape'){
      console.log('Escape press here! ')
    }
    if(event.keyCode === 27){
      event.preventDefault();
    }
    console.log(event.key)
    console.log(event.keyCode)
  }

  toggleModalincorporation = (state,addneworupdate) => {
    this.setState({modalincorporation:state})
    if(addneworupdate == 1){
      // addnew
      this.setState({addneworedituser:1})
    }else{
      //update
      this.setState({addneworedituser:2})
    }
   // console.log(state)
  };

  renderdashboardregister() {
    return (
    
               
      <tbody>
        
       <tr>

       <td>1)</td>
        <td >Company Details</td>
      
        <td >
        <Badge color="" className="badge-dot mr-4">
                                 
                                  <i className="bg-success" />                               
                                
                                Completed
                      </Badge></td>

                      </tr>
                      <tr>   
        <td>2)</td>
        <td >Particulars</td>
        <td >
        <Badge color="" className="badge-dot mr-4">
                                 
                                  <i className="bg-success" />                               
                                
                                Completed
                      </Badge></td>
                      </tr>

<tr>   
        <td>3)</td>
        <td >Shareholders / Members</td>
        <td >
        <Badge color="" className="badge-dot mr-4">
                                 
                                  <i className="bg-info" />                               
                                
                                To be completed
                      </Badge></td>
                        </tr>
                        <tr>   
        <td>4)</td>
        <td >Others information required</td>
        <td >
        <Badge color="" className="badge-dot mr-4">
                                 
                                  <i className="bg-info" />                               
                                
                                To be completed
                      </Badge></td>
                        </tr>
                        <tr>   
        <td>5)</td>
        <td >Others Services</td>
        <td >
        <Badge color="" className="badge-dot mr-4">
                                 
                                  <i className="bg-info" />                               
                                
                                To be completed
                      </Badge></td>
            </tr>    
           
         
          </tbody>

 
 

 )
    // return this.state.rotpendinglist.map((data, index) => {
    //    const {createdby,createdbywhoref,importexport, no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
    //    var rotlength = Object.keys(this.state.rotpendinglist).length;
    //    var volumeshow = totalvolume.slice(0,35)
    //    return (
    
            
    //         <tbody>
              
    //          <tr>
   
    //          <td>1)</td>
    //           <td >Company Details</td>
    //           <td >Completed</td>

    //           <td>2)</td>
    //           <td >Particulars</td>
    //           <td >Completed</td>

    //           <td>3)</td>
    //           <td >Shareholders / Members</td>
    //           <td >Draft</td>

    //           <td>4)</td>
    //           <td >Others information required</td>
    //           <td >Draft</td>

    //           <td>5)</td>
    //           <td >Others Services</td>
    //           <td >Completed</td>
    //               </tr>    
                
    //             </tbody>

       
       
   
    //    )
    // })
  }

  rendercompanydetail(){              
          return (        
            <div className="modal-body">
            <Form>
            {this.state.errorsubmit == true ?
                               <Alert color="danger">
                               {this.state.alerterrormessage}
                             </Alert>
                             :
                             null
                             }
            <Row>
              <Col md="12">
                <p className="h5">Company Name:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Company Name 1"
                    type="text"
                    value={this.state.companyname1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Company Name 2"
                    type="text"
                    value={this.state.companyname2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Company Name 3"
                    type="text"
                    value={this.state.companyname3}
                  />
                </FormGroup>
              </Col>              
            </Row>

            <Row>
            <Col md="12">
                <p className="h5">Nature of business:</p>
                <FormGroup>
                <Input
                    id="Natureofbusiness"
                    placeholder="Nature of Business 1"
                    className="textcolorblack"
                    type="text"
                    value={this.state.natureofbusiness1}                   
                  />
                   </FormGroup>
                   <FormGroup>
                    <Input
                    id="Natureofbusiness2"
                    placeholder="Nature of Business 2"
                    className="textcolorblack"
                    type="text"
                    value={this.state.natureofbusiness2}                   
                  />
                   </FormGroup>
                   <FormGroup>
                    <Input
                    id="Natureofbusines3s"
                    placeholder="Nature of Business 3"
                    className="textcolorblack"
                    type="text"
                    value={this.state.natureofbusiness3}                   
                  />
                   </FormGroup>
               
              </Col>
             
            </Row>

            <Row>
            <Col md="12">
                <p className="h5">Address:</p>
                <FormGroup>
                <Input
                    id="addressline1"
                    placeholder="Address line 1"
                    className="textcolorblack"
                    type="text"
                    value={this.state.addressline1}                   
                  />
                   </FormGroup>
                   <FormGroup>
                    <Input
                    id="addressline2"
                    placeholder="Address line 2"
                    className="textcolorblack"
                    type="text"
                    value={this.state.addressline2}                   
                  />
                   </FormGroup>
                   <FormGroup>
                    <Input
                    id="addressline3"
                    placeholder="Address line 3"
                    className="textcolorblack"
                    type="text"
                    value={this.state.addresslin3}                   
                  />
                   </FormGroup>
               
              </Col>
             
            </Row>        
          
          </Form>
         
       
            </div>
       
        )   
  }

  renderparticularsection(){              
    return (        
      <div className="modal-body">
      <Form>
      {this.state.errorsubmit == true ?
                         <Alert color="danger">
                         {this.state.alerterrormessage}
                       </Alert>
                       :
                       null
                       }
  
      {this.renderparticulargroupdetail()}

    
    </Form>
 
      </div>
 
  )   
  }

  renderparticulargroupdetail(){
    return (        
      <>
      
      <Card className="card-stats mb-4 mb-lg-0 bg-grey">
      <CardBody>
      <p className="h3">Particular 1:</p>

      <Form>
    
      {/* Name */}
      <Row>
        <Col md="12">
          <p className="h5">Name:</p>
          <FormGroup>
          <Input
              id="nameparticular1"
              placeholder="Name"
              value={this.state.nameparticular1} 
              className="textcolorblack"
              // onChange={e => this.setState({gp20pgu: e.target.value})}
            />
      </FormGroup> 
        </Col>              
      </Row>

      {/* Ic number */}
      <Row>
      <Col md="12">
          <p className="h5">NRIC No./Passport No.:</p>
          <FormGroup>
          <Input
              id="icorpassport"
              placeholder="NRIC No./Passport No."
              className="textcolorblack"
              type="text"
              value={this.state.icorpassport1}                   
            />
             </FormGroup>           
         
        </Col>
       
      </Row>

      {/* Contactno */}
      <Row>
      <Col md="12">
          <p className="h5">Contact No:</p>
          <FormGroup>
          <Input
              id="contactnumber"
              placeholder="Contact No."
              className="textcolorblack"
              type="text"
              value={this.state.contactnumber1}                   
            />
             </FormGroup>           
         
        </Col>
       
      </Row>

      {/* Email */}
      <Row>
      <Col md="12">
          <p className="h5">Email:</p>
          <FormGroup>
          <Input
              id="Email"
              placeholder="Email"
              className="textcolorblack"
              type="text"
              value={this.state.email1}           
            />
             </FormGroup>           
         
        </Col>
       
      </Row>
     
     {/* Residential Address */}
      <Row>
      <Col md="12">
          <p className="h5">Residential Address:</p>
          <FormGroup>
          <Input
              id="residentialaddress1"
              placeholder="Address line 1"
              className="textcolorblack"
              type="text"
              value={this.state.residentialaddress1}                   
            />
              <Input
              id="residentialaddress2"
              placeholder="Address line 2"
              className="textcolorblack"
              type="text"
              value={this.state.residentialaddress2}                   
            />
              <Input
              id="residentialaddress3"
              placeholder="Address line 3"
              className="textcolorblack"
              type="text"
              value={this.state.residentialaddress3}                   
            />
             </FormGroup>           
         
        </Col>
       
      </Row>

      {/* Shareholding */}
      <Row>
      <Col md="12">
          <p className="h5">Shareholding:</p>
          <FormGroup>
                            <Input
                                id="shareholding1"
                                placeholder="Shareholding"
                                type="number"
                                min="0"
                                value={this.state.shareholding1} 
                                className="textcolorblack"
                                // onChange={e => this.setState({gp20pgu: e.target.value})}
                              />
                        </FormGroup>            
         
        </Col>
       
      </Row>
      
      {/* Shareholder */}
      <Row>
      <Col md="12">
          <p className="h5">Shareholder:</p>
        <div className="custom-control1 custom-radio1 mb-3">
          <input
            className="custom-control1-input"
            defaultChecked
            id="customRadio1"
            name="custom-radio1-1"
            type="radio"
          />
          <label className="custom-control1-label" htmlFor="customRadio1">
            Yes
          </label>
        </div> 
        <div className="custom-control1 custom-radio1 mb-3">
          <input
            className="custom-control1-input"            
            id="customRadio2"
            name="custom-radio1-1"
            type="radio"
          />
          <label className="custom-control1-label" htmlFor="customRadio2">
            No
          </label>
        </div>
         
        </Col>
       
      </Row>
    
    
     {/* Director */}
      <Row>
      <Col md="12">
          <p className="h5">Director:</p>
        <div className="custom-control3 custom-radio3 mb-3">
          <input
            className="custom-control3-input"
            defaultChecked
            id="customRadio3"
            name="custom-radio-3"
            type="radio"
          />
          <label className="custom-control3-label" htmlFor="customRadio3">
            Yes
          </label>
        </div> 
        <div className="custom-control3 custom-radio3 mb-3">
          <input
            className="custom-control3-input"            
            id="customRadio4"
            name="custom-radio-3"
            type="radio"
          />
          <label className="custom-control3-label" htmlFor="customRadio4">
            No
          </label>
        </div>
         
        </Col>
       
      </Row>    

      {/* Type of Beneficial Owner */}
      <Row>
      <Col md="12">
          <p className="h5">Type of Beneficial Owner (BO):</p>
        <div className="custom-control5 custom-radio5 mb-3">
          <input
            className="custom-control5-input"
            defaultChecked
            id="customRadio5"
            name="custom-radio5-5"
            type="radio"
          />
          <label className="custom-control5-label" htmlFor="customRadio5">
            Direct
          </label>
        </div> 
        <div className="custom-control5 custom-radio5 mb-3">
          <input
            className="custom-control5-input"            
            id="customRadio6"
            name="custom-radio5-5"
            type="radio"
          />
          <label className="custom-control5-label" htmlFor="customRadio6">
          Indirect
          </label>
        </div>
         
        </Col>
       
      </Row>
    

      {/* Criteria of Beneficial Owner */}
      <Row>
      <Col md="12">
          <p className="h5">Criteria of Beneficial Owner (BO):</p>
        <div className="custom-control7 custom-radio5 mb-1">
          <input
            className="custom-control7-input"
            defaultChecked
            id="customRadio7"
            name="custom-radio7-5"
            type="radio"
          />
          <label className="custom-control7-label" htmlFor="customRadio7">
            Has interest, direct or indirectly, in not less than 20% of the shares;
          </label>
        </div> 
        <div className="custom-control7 custom-radio5 mb-1">
          <input
            className="custom-control7-input"            
            id="customRadio8"
            name="custom-radio7-5"
            type="radio"
          />
          <label className="custom-control5-label" htmlFor="customRadio8">
          Hold, directly or indirectly, not less than 20% of the voting shares of the company;
          </label>        
        </div>
        <div className="custom-control7 custom-radio5 mb-1">
          <input
            className="custom-control7-input"            
            id="customRadio9"
            name="custom-radio7-5"
            type="radio"
          />
          <label className="custom-control7-label" htmlFor="customRadio9">
          Has the right to exercise ultimate effective control whether formal or informal over the company; or the directors or the management of company;
          </label>          
          </div>
          <div className="custom-control7 custom-radio5 mb-1">
          <input
            className="custom-control7-input"            
            id="customRadio10"
            name="custom-radio7-5"
            type="radio"
          />
          <label className="custom-control7-label" htmlFor="customRadio10">
          has the right or power to directly or indirectly appoint or remove a director(s) who hold a majority of the voting rights at meeting of directors;
          </label> 
          </div>
          <div className="custom-control7 custom-radio5 mb-1">
          <input
            className="custom-control7-input"            
            id="customRadio11"
            name="custom-radio7-5"
            type="radio"
          />
          <label className="custom-control7-label" htmlFor="customRadio11">
          Is a member of the company and, under an agreement with another member of the company, controls alone a majority of the voting right in the company.
          </label>          
          </div>
        </Col>
       
      </Row>

      {/*indirect reigster form */}
      {this.renderindirectform()}

    
    </Form>
  
        </CardBody>
      </Card>
     
      </>
 
  )   
  }

  renderindirectform(){
    return(
      <>
         <Row>
              <Col md="12">
              <p className="h5">I am/we are holding the voting shares in the Company on behalf of another person as a trustee.</p>
              <p className="h5">The details of the BO are as below:</p>
                <p className="h5">Full Name:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Full Name"
                    type="text"
                    value={this.state.indirectfullname1}
                  />
                </FormGroup>
                <p className="h5">Residential Address:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Residential Address line 1"
                    type="text"
                    value={this.state.indirectresidentialaddressline1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Residential Address line 2"
                    type="text"
                    value={this.state.indirectresidentialaddressline2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Residential Address line 3"
                    type="text"
                    value={this.state.indirectresidentialaddressline3}
                  />
                </FormGroup>
                <p className="h5">Postcode:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Postcode"
                    type="text"
                    value={this.state.indirectpostcode}
                  />
                </FormGroup>
                <p className="h5">Town:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Town"
                    type="text"
                    value={this.state.indirecttown}
                  />
                </FormGroup>
                <p className="h5">State:</p>
                <FormGroup>
                  <Input                  
                    placeholder="State"
                    type="text"
                    value={this.state.indirectstate}
                  />
                </FormGroup>
                <p className="h5">Nationality:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Nationality"
                    type="text"
                    value={this.state.indirectnationality}
                  />
                </FormGroup>
                <p className="h5">Date of birth:</p>
                <FormGroup>
                  <Input                  
                    placeholder="State"
                    type="text"
                    value={this.state.indirectdateofbirth}
                  />
                </FormGroup>
                <p className="h5">NRIC:</p>
                <FormGroup>
                  <Input                  
                    placeholder="NRIC"
                    type="text"
                    value={this.state.indirectNRIC}
                  />
                </FormGroup>
                <p className="h5">Date of becoming a BO:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Date of becoming a BO"
                    type="text"
                    value={this.state.indirectdateofbecomingbo}
                  />
                </FormGroup>
                <p className="h5">Email:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Email"
                    type="text"
                    value={this.state.indirectemail}
                  />
                </FormGroup>
              </Col>              
            </Row>

      </>
    )
  }

  rendershareholdermembertable(){
    return(
      <>
          {/* Shareholder */}
            <Row>
            <Col md="1">   
            <p className="h5">No.</p>          
                <FormGroup>
                <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={1}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={2}
                    disabled
                  />  
                </FormGroup>
                <FormGroup>
                <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={3}
                    disabled
                  />  
                </FormGroup>
                <FormGroup>
                <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={4}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={5}
                    disabled
                  />
                </FormGroup>
                </Col> 
              <Col md="4">           
              <p className="h5">Name of Shareholder/Member</p>          
                <FormGroup>
                  <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={this.state.nameofshareholder1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={this.state.nameofshareholder2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={this.state.nameofshareholder3}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={this.state.nameofshareholder4}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={this.state.nameofshareholder5}
                  />
                </FormGroup>
                </Col> 
                <Col md="4"> 
                <p className="h5">IC / Passport / Company No:</p>
                <FormGroup>
                  <Input                  
                    placeholder="IC / Passport / Company No"
                    type="text"
                    value={this.state.icofshareholder1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="IC / Passport / Company No"
                    type="text"
                    value={this.state.icofshareholder2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="IC / Passport / Company No"
                    type="text"
                    value={this.state.icofshareholder3}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="IC / Passport / Company No"
                    type="text"
                    value={this.state.icofshareholder4}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="IC / Passport / Company No"
                    type="text"
                    value={this.state.icofshareholder5}
                  />
                </FormGroup>
                </Col> 
                <Col md="3"> 
                <p className="h5">Number of Share:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Number of Share"
                    type="text"
                    value={this.state.numberofshareshareholder1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Number of Share"
                    type="text"
                    value={this.state.numberofshareshareholder2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Number of Share"
                    type="text"
                    value={this.state.numberofshareshareholder3}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Number of Share"
                    type="text"
                    value={this.state.numberofshareshareholder4}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Number of Share"
                    type="text"
                    value={this.state.numberofshareshareholder5}
                  />
                </FormGroup>
              </Col>              
            </Row>


            {/* Signature */}
            <p className="h5 mt-5">Director 1</p>     
            <Row>              
             <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile3} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload3}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename3 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename3, "_blank")}>
                           Preview
                       </Button> 
                       }
                   </Col>
                    
             </Row>
      </>
    )
  }

  renderotherinformationrequired(){
    return(
      <>
        <p className="h2 mt-5">Other information required:</p> 
        <Row>
              <Col md="12">
                <p className="h5">Business Address:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Business Address 1"
                    type="text"
                    value={this.state.businessaddress1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Business Address 2"
                    type="text"
                    value={this.state.businessaddress2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Business Address 3"
                    type="text"
                    value={this.state.businessaddress3}
                  />
                </FormGroup>
              </Col>              
            </Row>

            <Row>
              <Col md="12">
                <p className="h5">Office Telephone Number:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Office Telephone Number"
                    type="text"
                    value={this.state.officetelephonenumber}
                  />
                </FormGroup>            
              </Col> 
              <Col md="12">
                <p className="h5">Office Email Address:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Office Email Address"
                    type="text"
                    value={this.state.officeemailaddress}
                  />
                </FormGroup>            
              </Col>  
              <Col md="12">
                <p className="h5">Financial year end:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Financial year end:"
                    type="text"
                    value={this.state.financialyearend}
                  />
                </FormGroup>            
              </Col>
              <Col md="12">
                <p className="h5">Bank Account:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Bank Account:"
                    type="text"
                    value={this.state.bankaccount}
                  />
                </FormGroup>            
              </Col>  
              <Col md="12">
                <p className="h5">Bank Authorised Signature:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Bank Authorised Signature:"
                    type="text"
                    value={this.state.bankauthorisesignature}
                  />
                </FormGroup>            
              </Col> 
              <Col md="12">
                <p className="h5">Online Banking:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Online Banking:"
                    type="text"
                    value={this.state.onlinebanking}
                  />
                </FormGroup>            
              </Col>    

            </Row>
      </>
    )
  }

  renderauditor(){
    return(
      <>
           <p className="h2 mt-2">Auditor:</p> 
           <p className="h4">Company ABC</p> 

           <p className="h2 mt-3">Tax Agent:</p> 
           <p className="h4  mb-3">Company ABC</p> 
      </>
    )
  }
  
  buttoncancelattachmentclick = () =>{
    this.setState({attachmentquantity:this.state.attachmentquantity - 1}) 
    
    if(this.state.attachmentquantity == 1){  
    this.setState({attachmentquantity:1})
    }else if(this.state.attachmentquantity == 2){
      this.setState({fileuploadnumber2:false})
    this.setState({fileuploadnumber3:false})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 3){
      this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:false})
      this.setState({fileuploadnumber4:false})
      this.setState({fileuploadnumber5:false})
      this.setState({fileuploadnumber6:false})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 4){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 5){
      this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:false})
      this.setState({fileuploadnumber6:false})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 6){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 7){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 8){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 9){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 10){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:true})
    this.setState({fileuploadnumber10:false})
    }
    console.log("attachmentquantity: " + this.state.attachmentquantity)
  }

  
  render() {
    return (
      <>
       <Header />
        {/* Page contentas */}
        <Container className=" mt--10" fluid>
          {/* Table */}
          <Row>
            {/* top category button */}
            <div className=" col-12 ">         
                <Col md="12  mt-3">      
                <div>                      
                        <div className="d-md-inline-block mt-6">
                    
                   
                        </div>
                  
                        
                 </div>   
                </Col>
            </div>

            {/*Content*/}
            <div className=" col-12 ">
              <Card className=" shadow  ">             
              {this.state.loading
                ?
                // loading
                <Row >
                  <Col md="12" >
                    <img 
                    className="img-center mt-5"
                    width="120"
                    height="120"
                    alt="..."
                    src={require("./../../assets/gif/lorrygif3.gif")}
                  />
                  </Col>
                </Row>
                :
                //All Category Content
                <CardBody >    
                  <TabContent  activeTab={"tabs" + this.state.tabs}>
                    {/* Incorporation tab */}
                    <TabPane tabId="tabs1">    
                      {/*Header*/}  
                      <Row>
                          {/*Add new Incorporation tab */}
                          <Col >
                            <div className=" col-9 d-md-inline-block ">                            
                            <Button className="col-12" color="primary" type="button" onClick={() => this.toggleModalincorporation(true,1)}>
                            Add New Incorporation
                            </Button>
                            </div>
                          </Col>

                          {/*Search Bar */}
                          <Col md="8 form-inline  d-md-flex">    
                            {/* Searchbar is here */}                      
                            {window.innerWidth <= 760 ?
                            <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                              
                                  <i className="fas fa-search" />
                                
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                            {this.state.searchtextvalue == "" ?
                            null:
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                              
                            <Button className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</Button >
                              
                            </InputGroupText>
                          </InputGroupAddon>
                            }
                                
                                
                              
                            </InputGroup>
                            :
                            <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                
                                  <i className="fas fa-search" />
                                  
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                              {this.state.searchtextvalue == "" ?
                              null:
                              <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                              <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                              
                            <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                                
                              </InputGroupText>
                            </InputGroupAddon>
                              }
                                
                                  
                              
                            </InputGroup>
                            }
                          </Col>
                      </Row>   

                      {/*Table Content*/}
                      <Table className="align-items-center" responsive>
                      <thead className="thead-light">
                          <tr>
                            <th>No.</th>
                            <th>ID.</th>
                            <th>Company Name</th>
                            <th>Registration No.</th> 
                            <th >Action</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                            
                        <tbody>              
                          <tr>    
                            <td>1)</td>
                            <td>IN000001</td>   
                            <td>ABC Company</td>
                            <td>9887564543</td>    
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>                                 
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
        
                          </tr> 
                          <tr>    
                            <td>2)</td>
                            <td>IN000002</td>   
                            <td>DEF Company</td>
                            <td>9887564543</td>  
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                               
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
        
                          </tr> 
                        </tbody>
                                        
                      </Table>  
                    </TabPane>
                  </TabContent>
                </CardBody>
              }
              </Card>
            </div>
          </Row>
          
        </Container>     
       
 {/*Modal modalincorporation*/}
 <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.modalincorporation}
          toggle={() => this.setState({modalincorporation:false})}
        >
          {this.state.loadingmodaleditorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
         <div>
         <div className="modal-header">
         
         {this.state.addneworedituser == 1
           ?
           <h1 className="modal-title">
             Add New Incorporation
           </h1>
           :
           <h1 className="modal-title">
             Update Incorporation
           </h1>
           }      
          
           <button
             aria-label="Close"
             className="close"
             data-dismiss="modal"
             type="button"
             onClick={() => this.setState({modalincorporation:false})}
           >
             <span aria-hidden={true}>×</span>
           </button>
         </div>
         <div className="modal-body p-10">
       {/*Body start here */}
       {/*Body start here */}
       {/*Body start here */}

       <div>
         {/* Table */}
         <Row>
           <div className=" col">           
             {
             /* Success Status*/
             this.state.aftersubmitstatus == 1
             ?             
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
               <img 
               className="img-quotationlogodoneneweditrot"
               width="120"
               height="120"
               alt="..."
               src={require("./../../assets/img/brand/logomewah4.png")}
             />
             <p className="word-quotationaddnewfinish" >Thanks for choosing Mewahtrans Logistics,</p>
             <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
             <ReactToPrint
         trigger={() =>   <Button
           color="primary"
           data-dismiss="modal"
           type="button"
           to="/admin/orders"
           >
           Print Rot
           </Button>}
         content={() => this.componentRef}
       />
           <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             onClick={() => {this.closeandrefreshaftersubmit()}}
             >
             Close
           </Button>
         
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Fail Status */
             this.state.aftersubmitstatus == 2
             ?              
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
             <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
             <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             className="buttonmiddle"
             onClick={() => this.setState({aftersubmitstatus:0})}
             >
             Retry
           </Button>
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Loading Status */
             this.state.aftersubmitstatus == 3
             ?
             <Row >
               <Col md="12" >
                 <img 
                 className="img-center"
                 width="120"
                 height="120"
                 alt="..."
                 src={require("./../../assets/gif/lorrygif3.gif")}
               />
               </Col>
           </Row>
             :             
             <Form>
          

                 {this.state.errorsubmit == true ?
                    <Alert color="danger">
                    {this.state.alerterrormessage}
                  </Alert>
                  :
                  null
                  }


            <div className="col">
                   <h3 className="mb-0">Company Details:</h3>
                 </div>

                 {this.rendercompanydetail()}

                  {/* particular section */}
                 <div className="col">
                   <h2 className="mb-4">Particular:</h2>
                
                   <Row>
                          <Col md="12">
                            <p className="h5">Number of Particulars:</p>
                            <FormGroup>
                            <Input
                                id="numberofparticular"
                                placeholder="1"
                                type="number"
                                min="0"
                                value={this.state.numberofparticulars} 
                                className="textcolorblack"
                                onChange={e => this.setState({gp20pgu: e.target.value})}
                              />
                        </FormGroup> 
                          </Col>              
                        </Row>
                 </div>
                 {this.renderparticularsection()}

                   {/* Shareholding section */}
                 <div className="col">
                   <h2 className="mb-4">Shareholders/Members:</h2>
                
                   {this.rendershareholdermembertable()}
                 </div>

                    {/* Other section */}
                 {this.renderotherinformationrequired()}

                   
                    {/* Auditor tax section */}
                   {this.renderauditor()}    


                    {/*Attached button*/}
                 <Row>
                   <Col md="12">
                   <h2 className="mb-4">Attachements:</h2>
                   </Col>
                 </Row>
                  <Row>                 
                
                      <Col md="5">
                          
                      <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile1} />       
                       
                     
                       </Col>
                       <Col md="1">
                          
                       <p className="h5">{this.state.percentupload1}%</p>    
                       
                     
                       </Col>
                       <Col md="3">
                         {this.state.selectedFilename1 == "" ?
                         null :
                         <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename1, "_blank")}>
                       Preview
                   </Button> 
                   }
                       
                      
                        
                          </Col>
                      
                    
                 </Row>
                 {this.state.fileuploadnumber2 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile2} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload2}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename2 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename2, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber3 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile3} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload3}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename3 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename3, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber4 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile4} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload4}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename4 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename4, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber5 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile5} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload5}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename5 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename5, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber6 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile6} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload6}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename6 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename6, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber7 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile7} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload7}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename7 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename7, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber8 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile8} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload8}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename8 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename8, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber9 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile9} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload9}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename9 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename9, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                         <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber10 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile10} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload10}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename10 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename10, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }   

                    <Row className="mt-5">
                   <Col md="12">
                   <h2 className="mb-4">Generate Resolution:</h2>
                   </Col>
                 </Row>
                    <Row>
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="customCheck1"
                        type="checkbox"
                      />
                      <label className="custom-control-label" htmlFor="customCheck1">
                        Generate Edited Profile Resolution
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        defaultChecked
                        id="customCheck2"
                        type="checkbox"
                      />
                      <label className="custom-control-label" htmlFor="customCheck2">
                      Generate Edited Shareholder Resolution
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="customCheck1"
                        type="checkbox"
                      />
                      <label className="custom-control-label" htmlFor="customCheck1">
                        Generate Edited Profile Resolution
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        defaultChecked
                        id="customCheck2"
                        type="checkbox"
                      />
                      <label className="custom-control-label" htmlFor="customCheck2">
                      Generate Edited Shareholder Resolution
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="customCheck1"
                        type="checkbox"
                      />
                      <label className="custom-control-label" htmlFor="customCheck1">
                        Generate Edited Profile Resolution
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        defaultChecked
                        id="customCheck2"
                        type="checkbox"
                      />
                      <label className="custom-control-label" htmlFor="customCheck2">
                      Generate Edited Shareholder Resolution
                      </label>
                    </div>
                    </Row>
           



      
             </Form>
                
             }

                    
        <div style={{ display: "none" }}>
        </div> 
        <Row>
           <Col md="3">
           <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending(this.state.editorderrotnumber)}>
           Submit
         </Button>
           </Col>
         </Row>

           </div>
         </Row>
       </div>
    

       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
      </div> 
      
         </div>}
        </Modal>

        
      </>
    );
  }
}



export default Incorporation;
