/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
* npm run build
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import classnames from "classnames";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
//import Moment from 'moment';
// reactstrap components
import { Card,
  Alert,
  Modal,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Form,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
  FormFeedback,

  Button,} from "reactstrap";
  import ReactToPrint from 'react-to-print';
  import { Link } from 'react-router-dom';
// core components
import ReactDatetime from "react-datetime";
import Header from "components/Headers/Header.jsx";
import Footer from "components/Footers/AdminFooter.jsx";
import { textChangeRangeIsUnchanged } from "typescript";
import axios ,{post} from 'axios'; 
import Moment from 'moment';

class Masterfile extends React.Component {

  constructor(props){
    super(props);
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
    }
  }

  state = {    
    tabs: 1,
    tabsimportexport:1,    
    attachmentlist:[],
    rotpendinglist:[],   
    categorytemplatelist:[
      {title:"Adopt Constitution"},
      {title:"Annual Return"},
    ],
    modalusershow:false,
    modalbusinesscategory:false,
    modalbusinesscode:false,
    modalsecretary:false,
    modaltax:false,
    modaltemplate:false,
    modalaudit:false,
    searchtextvalue:'',
    buttoncolor1:"default",
    buttoncolor2:"white",
    buttoncolor3:"white",
    buttoncolor4:"white",
    buttoncolor5:"white",
    buttoncolor6:"white",
    buttoncolor7:"white",
    rownumberofvariable:0,
    linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"http://crm.mewahtrans.com.my/api/img/profile_img/",
  }; 


  componentDidMount(){
   // this.getpendingorderlistapi("Import");
   document.addEventListener('keyup', (e) => {
    if (e.keyCode === 27){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.cancelledsearchbuttonclick()
      }
     
    }else if(e.keyCode === 13){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.searchfunctionstart()
      }
      
    }
    },false);
    this.setState({searchtextvalue:""})
 //  this.setdatedefaultincomplete();
   // this.getcreatedbywho();
   // this.getorderlistapi("Import");
    this.setState({tabsimportexport:1})
    this.setState({attachmentsetID:Moment().unix()}) ;   
  }

  categorybuttonclicked = (buttonnumber) =>{
    if(buttonnumber == 1){
      this.setState({buttoncolor1:"default"})
      this.setState({buttoncolor2:"white"})
      this.setState({buttoncolor3:"white"})
      this.setState({buttoncolor4:"white"})
      this.setState({buttoncolor5:"white"})
      this.setState({buttoncolor6:"white"})
      this.setState({buttoncolor7:"white"})
      this.setState({tabs:buttonnumber})
    }else if(buttonnumber == 2){
      this.setState({buttoncolor1:"white"})
      this.setState({buttoncolor2:"default"})
      this.setState({buttoncolor3:"white"})
      this.setState({buttoncolor4:"white"})
      this.setState({buttoncolor5:"white"})
      this.setState({buttoncolor6:"white"})
      this.setState({buttoncolor7:"white"})
      this.setState({tabs:buttonnumber})
    }else if(buttonnumber == 3){
      this.setState({buttoncolor1:"white"})
      this.setState({buttoncolor2:"white"})
      this.setState({buttoncolor3:"default"})
      this.setState({buttoncolor4:"white"})
      this.setState({buttoncolor5:"white"})
      this.setState({buttoncolor6:"white"})
      this.setState({buttoncolor7:"white"})
      this.setState({tabs:buttonnumber})
    }else if(buttonnumber == 4){
      this.setState({buttoncolor1:"white"})
      this.setState({buttoncolor2:"white"})
      this.setState({buttoncolor3:"white"})
      this.setState({buttoncolor4:"default"})
      this.setState({buttoncolor5:"white"})
      this.setState({buttoncolor6:"white"})
      this.setState({buttoncolor7:"white"})
      this.setState({tabs:buttonnumber})
    }else if(buttonnumber == 5){
      this.setState({buttoncolor1:"white"})
      this.setState({buttoncolor2:"white"})
      this.setState({buttoncolor3:"white"})
      this.setState({buttoncolor4:"white"})
      this.setState({buttoncolor5:"default"})
      this.setState({buttoncolor6:"white"})
      this.setState({buttoncolor7:"white"})
      this.setState({tabs:buttonnumber})
    }else if(buttonnumber == 6){
      this.setState({buttoncolor1:"white"})
      this.setState({buttoncolor2:"white"})
      this.setState({buttoncolor3:"white"})
      this.setState({buttoncolor4:"white"})
      this.setState({buttoncolor5:"white"})
      this.setState({buttoncolor6:"default"})
      this.setState({buttoncolor7:"white"})
      this.setState({tabs:buttonnumber})
    }else if(buttonnumber == 7){
      this.setState({buttoncolor1:"white"})
      this.setState({buttoncolor2:"white"})
      this.setState({buttoncolor3:"white"})
      this.setState({buttoncolor4:"white"})
      this.setState({buttoncolor5:"white"})
      this.setState({buttoncolor6:"white"})
      this.setState({buttoncolor7:"default"})
      this.setState({tabs:buttonnumber})
    }
    this.setState({searchtextvalue:''})
  }

  cancelledsearchbuttonclick = () =>{
    if(this.state.tabs == 1){
     this.setState({searchtextvalue:""})
     this.setState({currentcompletedpagenumber:1})
     if(this.state.tabsimportexport == 1){
      this.getpendingorderlistapi("Import");
     }else{
      this.getpendingorderlistapi("Export");
     }
     
    }else if( this.state.tabs ==2){
     this.setState({searchtextvalue:""})
     if(this.state.tabsimportexport == 1){
      this.getorderlistapi("Import");
     }else{
      this.getorderlistapi("Export");
     }
    }else if( this.state.tabs ==3){
      this.setState({searchtextvalue:""})
      if(this.state.tabsimportexport == 1){
        this.getcompletedorderapiwithdaterange("Import");
       }else{
        this.getcompletedorderapiwithdaterange("Export");
       }
     }      
  }

  nextpaginationfunction = (e) =>{
    if(this.state.completelist.length/100 <= this.state.currentcompletedpagenumber){
      
    }else{
     this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber +1})
    }
  }
 
  previouspaginationfunction = (e) =>{
   if(this.state.currentcompletedpagenumber== 1){
     
   }else{
    this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber - 1})
   }
  }

  renderTablePagination() {
         
      { if( this.state.completelist.length/100 < 1){
        return( null )
      }else if(this.state.completelist.length/100 >= 1 && this.state.completelist.length/100 < 2 ){
       return (
         
            
          <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                           <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                            <PaginationLink href="#pablo" onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>      
                 <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
                  
                 )
      }else if(this.state.completelist.length/100 >= 2 && this.state.completelist.length/100 < 3 ){
       return (
          
               
             <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>      
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               
             
            


                 )
      }else if(this.state.completelist.length/100 >= 3 && this.state.completelist.length/100 < 4 ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                       <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber ==2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>          
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      }else if(this.state.completelist.length/100 >= 4 && this.state.completelist.length/100 < 5 ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>   
                          <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                              5
                            </PaginationLink>
                          </PaginationItem>           
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      }else if(this.state.completelist.length/100 >= 5  ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                    <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>   
                          <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                              5
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 6 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:6})}>
                              ...
                            </PaginationLink>
                          </PaginationItem>           
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      } 
      
     }
  }
  
  handleKeyPress = (event) => {
    if(event.key === 'Escape'){
      console.log('Escape press here! ')
    }
    if(event.keyCode === 27){
      event.preventDefault();
    }
    console.log(event.key)
    console.log(event.keyCode)
  }
  
  //Modal
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
    console.log(state)
  };

  toggleModaluser = (state,addneworupdate) => {
    this.setState({modalusershow:state})
    if(addneworupdate == 1){
      // addnew
      this.setState({addneworedituser:1})
    }else{
      //update
      this.setState({addneworedituser:2})
    }
   // console.log(state)
  };

  toggleModalbusinesscategory = (state,addneworupdate) => {
    this.setState({modalbusinesscategory:state})
    if(addneworupdate == 1){
      // addnew
      this.setState({addneworedituser:1})
    }else{
      //update
      this.setState({addneworedituser:2})
    }
   // console.log(state)
  };

  toggleModalbusinesscode = (state,addneworupdate) => {
    this.setState({modalbusinesscode:state})
    if(addneworupdate == 1){
      // addnew
      this.setState({addneworedituser:1})
    }else{
      //update
      this.setState({addneworedituser:2})
    }
   // console.log(state)
  };

  toggleModalsecretary = (state,addneworupdate) => {
    this.setState({modalsecretary:state})
    if(addneworupdate == 1){
      // addnew
      this.setState({addneworedituser:1})
    }else{
      //update
      this.setState({addneworedituser:2})
    }
   // console.log(state)
  };

  toggleModalaudit = (state,addneworupdate) => {
    this.setState({modalaudit:state})
    if(addneworupdate == 1){
      // addnew
      this.setState({addneworedituser:1})
    }else{
      //update
      this.setState({addneworedituser:2})
    }
  }

  toggleModaltax = (state,addneworupdate) => {
      this.setState({modaltax:state})
      if(addneworupdate == 1){
        // addnew
        this.setState({addneworedituser:1})
      }else{
        //update
        this.setState({addneworedituser:2})
      }
  }

  toggleModaltemplate = (state,addneworupdate) => {
        this.setState({modaltemplate:state})
        if(addneworupdate == 1){
          // addnew
          this.setState({addneworedituser:1})
        }else{
          //update
          this.setState({addneworedituser:2})
        }
  }


  // render


  //Function
  rendervariablesection() {
    let variablerow=[];
    for(let i=0;i<this.state.rownumberofvariable;i++){
      variablerow.push(
        <Row>
        <Col md="6">                       
            <FormGroup>
              <Input
                placeholder="Variable ID"
                value={this.state.Billtoparty}
                type="text"
                disabled
              />
            </FormGroup>
          </Col>
          <Col md="6">                       
            <FormGroup>
              <Input
                placeholder="Variable Data"
                value={this.state.Billtoparty}
                type="text"
                disabled
              />
            </FormGroup>
          </Col>
        </Row> 
      )  
    }
      return variablerow
  
  }

  //button click  
  buttoncancelattachmentclick = () =>{
    this.setState({attachmentquantity:this.state.attachmentquantity - 1}) 
    
    if(this.state.attachmentquantity == 1){  
    this.setState({attachmentquantity:1})
    }else if(this.state.attachmentquantity == 2){
      this.setState({fileuploadnumber2:false})
    this.setState({fileuploadnumber3:false})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 3){
      this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:false})
      this.setState({fileuploadnumber4:false})
      this.setState({fileuploadnumber5:false})
      this.setState({fileuploadnumber6:false})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 4){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 5){
      this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:false})
      this.setState({fileuploadnumber6:false})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 6){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 7){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 8){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 9){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 10){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:true})
    this.setState({fileuploadnumber10:false})
    }
    console.log("attachmentquantity: " + this.state.attachmentquantity)
  }

  addvolumebuttonclicked = () =>{  
     this.setState({rownumberofvariable:this.state.rownumberofvariable + 1})
     console.log(this.state.rownumberofvariable)
  }
  
  minusvolumebuttonclicked = () =>{
    if(this.state.rownumberofvariable == 0){

    }else{
      this.setState({rownumberofvariable:this.state.rownumberofvariable - 1})
    }
    console.log(this.state.rownumberofvariable)
  }
  


  render() {
    return (
      <>
        <Header />
        {/* Page contentas */}
        <Container className=" mt--10" fluid>
          {/* Table */}
          <Row>
            {/* top category button */}
            <div className=" col-12 ">         
                <Col md="12  mt-3">      
                <div>                      
                        <div className="d-md-inline-block mt-6">
                        <NavLink className="nav-link-icon d-md-inline-block ">
                      <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor1} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.categorybuttonclicked(1)}>
                        <i className="ni ni-single-02  mr-2" />
                          User
                      </Button>
                      </NavLink>  
                      <NavLink className="nav-link-icon d-md-inline-block ">
                      <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor2} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.categorybuttonclicked(2)}>
                        <i className="ni ni-archive-2  mr-2" />
                        Business Category
                      </Button>
                      </NavLink>  
                      <NavLink className="nav-link-icon d-md-inline-block ">
                      <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor3} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.categorybuttonclicked(3)}>
                        <i className="ni ni-building  mr-2" />
                        Business Code
                      </Button>
                      </NavLink>
                      <NavLink className="nav-link-icon d-md-inline-block ">
                      <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor4} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.categorybuttonclicked(4)}>
                        <i className="ni ni-folder-17  mr-2" />
                        Secretary
                      </Button>
                      </NavLink>
                      <NavLink className="nav-link-icon d-md-inline-block ">
                      <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor5} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.categorybuttonclicked(5)}>
                        <i className="ni ni-zoom-split-in mr-2" />
                        Audit
                      </Button>
                      </NavLink>
                      <NavLink className="nav-link-icon d-md-inline-block ">
                      <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor6} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.categorybuttonclicked(6)}>
                        <i className="ni ni-money-coins  mr-2" />
                        Tax
                      </Button>
                      </NavLink>
                      <NavLink className="nav-link-icon d-md-inline-block ">
                      <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor7} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.categorybuttonclicked(7)}>
                        <i className="ni ni-ruler-pencil  mr-2" />
                        Template
                      </Button>
                      </NavLink>
                        </div>
                  
                        
                 </div>   
                </Col>
            </div>

            {/*Content*/}
            <div className=" col-12 ">
              <Card className=" shadow  ">             
              {this.state.loading
                ?
                // loading
                <Row >
                  <Col md="12" >
                    <img 
                    className="img-center mt-5"
                    width="120"
                    height="120"
                    alt="..."
                    src={require("./../../assets/gif/lorrygif3.gif")}
                  />
                  </Col>
                </Row>
                :
                //All Category Content
                <CardBody >    
                  <TabContent  activeTab={"tabs" + this.state.tabs}>
                    {/* User tab */}
                    <TabPane tabId="tabs1">    
                      {/*Header*/}  
                      <Row>
                          {/*Add new User tab */}
                          <Col >
                            <div className=" col-9 d-md-inline-block ">                            
                            <Button className="col-12" color="primary" type="button" onClick={() => this.toggleModaluser(true,1)}>
                              + Add New User
                            </Button>
                            </div>
                          </Col>

                          {/*Search Bar */}
                          <Col md="8 form-inline  d-md-flex">    
                            {/* Searchbar is here */}                      
                            {window.innerWidth <= 760 ?
                            <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                              
                                  <i className="fas fa-search" />
                                
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                            {this.state.searchtextvalue == "" ?
                            null:
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                              
                            <Button className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</Button >
                              
                            </InputGroupText>
                          </InputGroupAddon>
                            }
                                
                                
                              
                            </InputGroup>
                            :
                            <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                
                                  <i className="fas fa-search" />
                                  
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                              {this.state.searchtextvalue == "" ?
                              null:
                              <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                              <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                              
                            <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                                
                              </InputGroupText>
                            </InputGroupAddon>
                              }
                                
                                  
                              
                            </InputGroup>
                            }
                          </Col>
                      </Row>   

                      {/*Table Content*/}
                      <Table className="align-items-center" responsive>
                      <thead className="thead-light">
                          <tr>
                            <th>No.</th>
                            <th>ID.</th>
                            <th>Name</th>
                            <th>IC</th>
                            <th>Phone No.</th>
                            <th >Action</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                            
                        <tbody>              
                          <tr>    
                            <td>1)</td>
                            <td>KEM000001</td>   
                            <td>ALEX</td>
                            <td>987654321</td> 
                            <td>012345678</td>        
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
        
                          </tr> 
                          <tr>    
                            <td>2)</td>
                            <td>KEM000002</td>   
                            <td>BRANDA</td>
                            <td>987654322</td> 
                            <td>012345679</td>        
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
        
                          </tr> 
                        </tbody>
                                        
                      </Table>  
                    </TabPane>
                    
                    {/* Business Category tab */}
                    <TabPane tabId="tabs2">  
                      {/*Header*/}  
                      <Row>
                            {/*Add new User tab */}
                            <Col >
                            <div className=" col-9 d-md-inline-block ">                            
                            <Button className="col-12" color="primary" type="button" onClick={() => this.toggleModalbusinesscategory(true,1)}>
                              + Add New Business Category
                            </Button>
                            </div>
                          </Col>

                          {/*Search Bar */}
                          <Col md="8 form-inline  d-md-flex">    
                            {/* Searchbar is here */}                      
                            {window.innerWidth <= 760 ?
                            <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                              
                                  <i className="fas fa-search" />
                                
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                            {this.state.searchtextvalue == "" ?
                            null:
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                              
                            <Button className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</Button >
                              
                            </InputGroupText>
                          </InputGroupAddon>
                            }
                                
                                
                              
                            </InputGroup>
                            :
                            <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                
                                  <i className="fas fa-search" />
                                  
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                              {this.state.searchtextvalue == "" ?
                              null:
                              <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                              <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                              
                            <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                                
                              </InputGroupText>
                            </InputGroupAddon>
                              }
                                
                                  
                              
                            </InputGroup>
                            }
                          </Col>
                      </Row>   

                      {/*Table Content*/}
                      <Table className="align-items-center" responsive>
                      <thead className="thead-light">
                          <tr>
                            <th>No.</th>
                            <th>Business Category.</th>                          
                            <th >Action</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                            
                        <tbody>              
                          <tr>    
                            <td>1)</td>
                            <td>Lawyer Firm</td>   
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                          <tr>    
                            <td>2)</td>
                            <td>Food and Beverage</td>   
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                        </tbody>
                                        
                      </Table>  
                    </TabPane>

                    {/* Business Code tab */}
                    <TabPane tabId="tabs3">  
                      {/*Header*/}  
                      <Row>
                         {/*Add new User tab */}
                         <Col >
                            <div className=" col-9 d-md-inline-block ">                            
                            <Button className="col-12" color="primary" type="button" onClick={() => this.toggleModalbusinesscode(true,1)}>
                              + Add New Business Code
                            </Button>
                            </div>
                          </Col>

                          {/*Search Bar */}
                          <Col md="8 form-inline  d-md-flex">    
                            {/* Searchbar is here */}                      
                            {window.innerWidth <= 760 ?
                            <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                              
                                  <i className="fas fa-search" />
                                
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                            {this.state.searchtextvalue == "" ?
                            null:
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                              
                            <Button className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</Button >
                              
                            </InputGroupText>
                          </InputGroupAddon>
                            }
                                
                                
                              
                            </InputGroup>
                            :
                            <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                
                                  <i className="fas fa-search" />
                                  
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                              {this.state.searchtextvalue == "" ?
                              null:
                              <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                              <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                              
                            <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                                
                              </InputGroupText>
                            </InputGroupAddon>
                              }
                                
                                  
                              
                            </InputGroup>
                            }
                          </Col>
                      </Row>   

                      {/*Table Content*/}
                      <Table className="align-items-center" responsive>
                      <thead className="thead-light">
                          <tr>
                            <th>No.</th>
                            <th>Business Code</th> 
                            <th>Business Category</th>      
                            <th>Description</th>                       
                            <th >Action</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                            
                        <tbody>              
                          <tr>    
                            <td>1)</td>
                            <td>BC000001</td>   
                            <td>Lawyer Firm</td>   
                            <td>To help people claim back,what they should be</td> 
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                          <tr>    
                          <td>2)</td>
                            <td>BC000002</td>   
                            <td>Food and Beverage</td>   
                            <td>To provide food and beverage for customer</td> 
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                        </tbody>
                                        
                      </Table>  
                    </TabPane>
                    
                    {/* Secretary tab */}
                    <TabPane tabId="tabs4">  
                      {/*Header*/}  
                      <Row>
                           {/*Add new User tab */}
                           <Col >
                            <div className=" col-9 d-md-inline-block ">                            
                            <Button className="col-12" color="primary" type="button" onClick={() => this.toggleModalsecretary(true,1)}>
                              + Add New Secretary
                            </Button>
                            </div>
                          </Col>

                          {/*Search Bar */}
                          <Col md="8 form-inline  d-md-flex">    
                            {/* Searchbar is here */}                      
                            {window.innerWidth <= 760 ?
                            <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                              
                                  <i className="fas fa-search" />
                                
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                            {this.state.searchtextvalue == "" ?
                            null:
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                              
                            <Button className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</Button >
                              
                            </InputGroupText>
                          </InputGroupAddon>
                            }
                                
                                
                              
                            </InputGroup>
                            :
                            <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                
                                  <i className="fas fa-search" />
                                  
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                              {this.state.searchtextvalue == "" ?
                              null:
                              <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                              <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                              
                            <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                                
                              </InputGroupText>
                            </InputGroupAddon>
                              }
                                
                                  
                              
                            </InputGroup>
                            }
                          </Col>
                      </Row>   

                      {/*Table Content*/}
                      <Table className="align-items-center" responsive>
                      <thead className="thead-light">
                          <tr>
                            <th>No.</th>
                            <th>Secretary.</th>                          
                            <th >Action</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                            
                        <tbody>              
                          <tr>    
                            <td>1)</td>
                            <td>Secretary Company A</td>   
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                          <tr>    
                            <td>2)</td>
                            <td>Secretary Company B</td>   
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                        </tbody>
                                        
                      </Table>  
                    </TabPane>
                    
                    {/* Audit tab */}
                    <TabPane tabId="tabs5">  
                      {/*Header*/}  
                      <Row>
                          {/*Add new User tab */}
                          <Col >
                            <div className=" col-3 d-md-inline-block ">                            
                            <Button className="col-12" color="primary" type="button" onClick={() => this.toggleModalaudit(true,1)}>
                              + Add New Audit
                            </Button>
                            </div>
                          </Col>
                      </Row>   

                      {/*Table Content*/}
                      <Table className="align-items-center" responsive>
                      <thead className="thead-light">
                          <tr>
                            <th>No.</th>
                            <th>Auditor</th>                          
                            <th >Action</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                            
                        <tbody>              
                          <tr>    
                            <td>1)</td>
                            <td>Audit Firm Company A</td>   
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                          <tr>    
                            <td>2)</td>
                            <td>Audit Firm Company B</td>   
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                        </tbody>
                                        
                      </Table>  
                    </TabPane>

                    {/* Tax tab */}
                    <TabPane tabId="tabs6">  
                      {/*Header*/}  
                      <Row>
                            {/*Add new User tab */}
                            <Col >
                            <div className=" col-9 d-md-inline-block ">                            
                            <Button className="col-12" color="primary" type="button" onClick={() => this.toggleModaltax(true,1)}>
                              + Add New Tax
                            </Button>
                            </div>
                          </Col>

                          {/*Search Bar */}
                          <Col md="8 form-inline  d-md-flex">    
                            {/* Searchbar is here */}                      
                            {window.innerWidth <= 760 ?
                            <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                              
                                  <i className="fas fa-search" />
                                
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                            {this.state.searchtextvalue == "" ?
                            null:
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                              
                            <Button className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</Button >
                              
                            </InputGroupText>
                          </InputGroupAddon>
                            }
                                
                                
                              
                            </InputGroup>
                            :
                            <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                
                                  <i className="fas fa-search" />
                                  
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                              {this.state.searchtextvalue == "" ?
                              null:
                              <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                              <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                              
                            <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                                
                              </InputGroupText>
                            </InputGroupAddon>
                              }
                                
                                  
                              
                            </InputGroup>
                            }
                          </Col>
                      </Row>   

                      {/*Table Content*/}
                      <Table className="align-items-center" responsive>
                      <thead className="thead-light">
                          <tr>
                            <th>No.</th>
                            <th>Tax Agent</th>                          
                            <th >Action</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                            
                        <tbody>              
                          <tr>    
                            <td>1)</td>
                            <th>Tax Company A</th>   
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                          <tr>    
                            <td>2)</td>
                            <th>Tax Company B</th>    
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                        </tbody>
                                        
                      </Table>  
                    </TabPane>

                    {/* Template tab */}
                    <TabPane tabId="tabs7">  
                      {/*Header*/}  
                      {/*Add new User tab */}
                      <Row>
                          {/*Add new User tab */}
                          <Col >
                            <div className=" col-3 d-md-inline-block ">                            
                            <Button className="col-12" color="primary" type="button" onClick={() => this.toggleModaltemplate(true,1)}>
                              + Add New Template
                            </Button>
                            </div>
                          </Col>
                      </Row>
                       {/*Filter and Search Bar */}
                      <Row>
                        <Col md="1">
                        <p style={{marginLeft:10,marginTop:10}}>Filter</p>
                        </Col>  
                      <Col md="3">
                            <div className=" col-10 d-md-inline-block ml--5">
                            <div className="nav-wrapper ">  
                            <Autocomplete
                                id="combo-box-demo2"
                                options={this.state.categorytemplatelist}
                                getOptionLabel={(option) => option.title}                       
                                style={{ flex:1,display:"block" }}
                                onInputChange={(event,value) =>this.setState({areadropdowntext:value})}
                                renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}
                                disableClearable={true}
                              />
                            </div>
                            </div>
                          </Col>                       
                          <Col md="8 form-inline  d-md-flex">    
                            {/* Searchbar is here */}                      
                            {window.innerWidth <= 760 ?
                            <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                              
                                  <i className="fas fa-search" />
                                
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                            {this.state.searchtextvalue == "" ?
                            null:
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                              
                            <Button className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</Button >
                              
                            </InputGroupText>
                          </InputGroupAddon>
                            }
                                
                                
                              
                            </InputGroup>
                            :
                            <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                
                                  <i className="fas fa-search" />
                                  
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                              {this.state.searchtextvalue == "" ?
                              null:
                              <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                              <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                              
                            <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                                
                              </InputGroupText>
                            </InputGroupAddon>
                              }
                                
                                  
                              
                            </InputGroup>
                            }
                          </Col>
                      </Row>   

                      {/*Table Content*/}
                      <Table className="align-items-center" responsive>
                      <thead className="thead-light">
                          <tr>
                            <th>No.</th>
                            <th>Template ID</th>   
                            <th>Template Name</th>     
                            <th>Template Category</th>                      
                            <th >Action</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                            
                        <tbody>              
                          <tr>    
                            <td>1)</td>
                            <td>TP00001</td>   
                            <td>Move money to bank</td>  
                            <td>Move Fund</td>  
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                          <tr>    
                          <td>2)</td>
                            <td>TP00002</td>   
                            <td>Change Bank</td>  
                            <td>Move Fund</td>  
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
                          </tr> 
                        </tbody>
                                        
                      </Table>  
                    </TabPane>
                    
                    
                  </TabContent>
                </CardBody>
              }
              </Card>
            </div>
          </Row>
          
        </Container>     
       

          {/*Modal User*/}
          <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.modalusershow}
          toggle={() => this.setState({modalusershow:false})}
        >
          {this.state.loadingmodaleditorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
          <div>
          <div className="modal-header">
            {this.state.addneworedituser == 1
            ?
            <h1 className="modal-title">
              Add New User
            </h1>
            :
            <h1 className="modal-title">
              Update User
            </h1>
            }
           
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({modalusershow:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
        {/*Body start here */}
        {/*Body start here */}
        {/*Body start here */}

        <div>
          {/* Table */}
          <Row>
            <div className=" col">           
              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodoneneweditrot"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Thanks for choosing Mewahtrans Logistics,</p>
              <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
              <ReactToPrint
          trigger={() =>   <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            to="/admin/orders"
            >
            Print Rot
            </Button>}
          content={() => this.componentRef}
        />
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => {this.closeandrefreshaftersubmit()}}
              >
              Close
            </Button>
          
            </Col>
            
          </Row>
          </Form>
         </div>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?              
              <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={() => this.setState({aftersubmitstatus:0})}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :             
              <Form>
           

                  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }

                    {/*Profile Picture*/}
                    <Row>
                      <Col md="4">
                      </Col>  
                      <Col md="4">
                      <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          width="125"
                          height="125"
                          className="rounded-circle"
                          src={require("./../../assets/img/profilepicturedefault.png")}
                          onClick={() => this.toggleModal("defaultModal")}
                        />
                      </a>
                    </div>
                       </Col> 
                       <Col md="4">
                      </Col>                             
                  </Row>
                  <Row className="mt-8">
                      <Col md="4">
                      </Col>  
                      <Col md="4">
                            <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile10} />       
                       </Col> 
                       <Col md="4">
                      </Col>                             
                  </Row>
                  
                 
                   {/*Username, Password*/}
                  <Row className="mt-5">
                    <Col md="6">
                        <p className="h5">Username:</p>
                        <FormGroup>
                          <Input
                            placeholder="Username"
                            value={this.state.Billtoparty}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>    
                      <Col md="6">
                        <p className="h5">Password:</p>
                        <FormGroup>
                          <Input
                            placeholder="Password"
                            value={this.state.Billtoparty}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>                                 
                  </Row>
               
                  {/*Name,IC*/}
                  <Row>
                     {/*Name*/}
                    <Col md="6">
                      <p className="h5">Name:</p>
                      <FormGroup>
                        <Input
                          id="Billtopartyinput1"
                          placeholder="Bill To Party"
                          value={this.state.Billtoparty}
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col> 

                    {/*NRIC No./Passport No.*/}
                    <Col md="6">
                      <p className="h5">NRIC No./Passport No.:</p>
                      <FormGroup>
                        <Input
                          placeholder="NRIC No./Passport No."
                          value={this.state.Billtoparty}
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>                    
                  </Row>

                   {/*Contact Number,Email.*/}
                   <Row>
                    <Col md="6">
                        <p className="h5">Contact Number:</p>
                        <FormGroup>
                          <Input
                            placeholder="Contact Number"
                            value={this.state.Billtoparty}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>    
                      <Col md="6">
                        <p className="h5">Email:</p>
                        <FormGroup>
                          <Input
                            placeholder="Email"
                            value={this.state.Billtoparty}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>                                 
                  </Row>

                    {/*Residential Address*/}
                    <Row>
                    <Col md="12">
                      <p className="h5">Residential Address:</p>
                      <FormGroup>
                        <Input
                          placeholder="Address Line 1"
                          value={this.state.Billtoparty}
                          type="text"
                          disabled
                        />
                      </FormGroup>                    
                    </Col>  
                  </Row>
                  <Row>
                    <Col md="12">                 
                      <FormGroup>
                        <Input
                          placeholder="Address Line 2"
                          value={this.state.Billtoparty}
                          type="text"
                          disabled
                        />
                      </FormGroup>                    
                    </Col>  
                  </Row>
                  <Row>
                    <Col md="12">                 
                      <FormGroup>
                        <Input
                          placeholder="Address Line 3"
                          value={this.state.Billtoparty}
                          type="text"
                          disabled
                        />
                      </FormGroup>                    
                    </Col>  
                  </Row>

                  {/*State,Country*/}
                  <Row>
                    <Col md="6">
                        <p className="h5">State:</p>
                        <FormGroup>
                          <Input
                            placeholder="State"
                            value={this.state.Billtoparty}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>    
                      <Col md="6">
                        <p className="h5">Country:</p>
                        <FormGroup>
                          <Input
                            placeholder="Country"
                            value={this.state.Billtoparty}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>                                 
                  </Row>

                  {/*IC Copy*/}
                 <Row className="mt-2">
                 <Col md="12">      
                 <p className="h5">IC Copy</p>   
                         <Row>                                
                           <Col md="5">
                            <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile10} />       
                            
                          
                            </Col>
                            {/* <Col md="1">
                            <p className="h5">{this.state.percentupload10}%</p>   
                            </Col>
                            <Col md="3">
                              {this.state.selectedFilename10 == "" ?
                              null :
                              <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename10, "_blank")}>
                            Preview
                        </Button> 
                        }
                            
                            
                              
                                </Col>
                        <Col md="1">
                        <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                              <span className="mb-0 text-sm">
                            X
                      </span>
                        </button>      
                        </Col> */}
                    </Row>
                
                    </Col>  
                 </Row>
                     {/*Signature Copy*/}
                     <Row className="mt-2">
                 <Col md="12">      
                 <p className="h5">Signature </p>   
                         <Row>                                
                           <Col md="5">
                            <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile10} />       
                            
                          
                            </Col>
                            {/* <Col md="1">
                            <p className="h5">{this.state.percentupload10}%</p>   
                            </Col>
                            <Col md="3">
                              {this.state.selectedFilename10 == "" ?
                              null :
                              <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename10, "_blank")}>
                            Preview
                        </Button> 
                        }
                            
                            
                              
                                </Col>
                        <Col md="1">
                        <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                              <span className="mb-0 text-sm">
                            X
                      </span>
                        </button>      
                        </Col> */}
                    </Row>
                
                    </Col>  
                 </Row>

                 {/*Submit*/}
                 <Row className="mt-3">
                    <Col md="3">
                    <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending(this.state.editorderrotnumber)}>
                    Submit
                  </Button>
                    </Col>
                  </Row>
              </Form>
                 
              }

                     
         <div style={{ display: "none" }}>
         </div> 


            </div>
          </Row>

        </div>
      

        {/*Body  end here */}
        {/*Body  end here */}
        {/*Body  end here */}
        {/*Body  end here */}
       </div>
        
      
       
       
          </div>}
          </Modal>
        
        {/*Modal Business Category*/}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.modalbusinesscategory}
          toggle={() => this.setState({modalbusinesscategory:false})}
        >
          {this.state.loadingmodaleditorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
          <div>
          <div className="modal-header">
            {this.state.addneworedituser == 1
            ?
            <h1 className="modal-title">
              Add New Business Category
            </h1>
            :
            <h1 className="modal-title">
              Update Business Category
            </h1>
            }
           
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({modalbusinesscategory:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
        {/*Body start here */}
        {/*Body start here */}
        {/*Body start here */}

        <div>
          {/* Table */}
          <Row>
            <div className=" col">           
              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodoneneweditrot"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Thanks for choosing Mewahtrans Logistics,</p>
              <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
              <ReactToPrint
          trigger={() =>   <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            to="/admin/orders"
            >
            Print Rot
            </Button>}
          content={() => this.componentRef}
        />
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => {this.closeandrefreshaftersubmit()}}
              >
              Close
            </Button>
          
            </Col>
            
          </Row>
          </Form>
         </div>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?              
              <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={() => this.setState({aftersubmitstatus:0})}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :             
              <Form>
           

                  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }

                    {/*Category , Prefix*/}
                    <Row className="mt-5">
                    <Col md="6">
                        <p className="h5">Business Category:</p>
                        <FormGroup>
                          <Input
                            placeholder="Business Category"
                            value={this.state.Billtoparty}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>    
                      <Col md="6">
                        <p className="h5">Prefix:</p>
                        <FormGroup>
                          <Input
                            placeholder="Prefix"
                            value={this.state.Billtoparty}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>                                 
                  </Row>
               
                   
              </Form>
                 
              }

                     
         <div style={{ display: "none" }}>
         </div> 
         <Row>
            <Col md="3">
            <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending(this.state.editorderrotnumber)}>
            Submit
          </Button>
            </Col>
          </Row>

            </div>
          </Row>
        </div>
     

        {/*Body  end here */}
        {/*Body  end here */}
        {/*Body  end here */}
        {/*Body  end here */}
       </div> 
       
          </div>}
        </Modal>

        {/*Modal Business Code*/}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.modalbusinesscode}
          toggle={() => this.setState({modalbusinesscode:false})}
        >
          {this.state.loadingmodaleditorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
         <div>
         <div className="modal-header">
           {this.state.addneworedituser == 1
           ?
           <h1 className="modal-title">
             Add New Business Code
           </h1>
           :
           <h1 className="modal-title">
             Update Business Code
           </h1>
           }
          
           <button
             aria-label="Close"
             className="close"
             data-dismiss="modal"
             type="button"
             onClick={() => this.setState({modalbusinesscode:false})}
           >
             <span aria-hidden={true}>×</span>
           </button>
         </div>
         <div className="modal-body p-10">
       {/*Body start here */}
       {/*Body start here */}
       {/*Body start here */}

       <div>
         {/* Table */}
         <Row>
           <div className=" col">           
             {
             /* Success Status*/
             this.state.aftersubmitstatus == 1
             ?             
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
               <img 
               className="img-quotationlogodoneneweditrot"
               width="120"
               height="120"
               alt="..."
               src={require("./../../assets/img/brand/logomewah4.png")}
             />
             <p className="word-quotationaddnewfinish" >Thanks for choosing Mewahtrans Logistics,</p>
             <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
             <ReactToPrint
         trigger={() =>   <Button
           color="primary"
           data-dismiss="modal"
           type="button"
           to="/admin/orders"
           >
           Print Rot
           </Button>}
         content={() => this.componentRef}
       />
           <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             onClick={() => {this.closeandrefreshaftersubmit()}}
             >
             Close
           </Button>
         
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Fail Status */
             this.state.aftersubmitstatus == 2
             ?              
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
             <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
             <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             className="buttonmiddle"
             onClick={() => this.setState({aftersubmitstatus:0})}
             >
             Retry
           </Button>
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Loading Status */
             this.state.aftersubmitstatus == 3
             ?
             <Row >
               <Col md="12" >
                 <img 
                 className="img-center"
                 width="120"
                 height="120"
                 alt="..."
                 src={require("./../../assets/gif/lorrygif3.gif")}
               />
               </Col>
           </Row>
             :             
             <Form>
          

                 {this.state.errorsubmit == true ?
                    <Alert color="danger">
                    {this.state.alerterrormessage}
                  </Alert>
                  :
                  null
                  }

                   {/*Category , Prefix*/}
                   <Row className="mt-5">
                   <Col md="6">
                       <p className="h5">Business Code:</p>
                       <FormGroup>
                         <Input
                           placeholder="Business Code"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>    
                     <Col md="6">
                       <p className="h5">Business Category:</p>
                       <FormGroup>
                         <Input
                           placeholder="Business Category"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                                 
                 </Row>
              
                      {/*Content*/}
                      <Row className="mt-1">
                   <Col md="12">
                       <p className="h5">Nature of Business:</p>
                       <FormGroup>
                         <Input
                           placeholder="Nature of Business"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>    
                                         
                 </Row>
             </Form>
                
             }

                    
        <div style={{ display: "none" }}>
        </div> 
        <Row>
           <Col md="3">
           <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending(this.state.editorderrotnumber)}>
           Submit
         </Button>
           </Col>
         </Row>

           </div>
         </Row>
       </div>
    

       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
      </div> 
      
         </div>}
        </Modal>

        {/*Modal Secretary*/}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.modalsecretary}
          toggle={() => this.setState({modalsecretary:false})}
        >
          {this.state.loadingmodaleditorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
         <div>
         <div className="modal-header">
           {this.state.addneworedituser == 1
           ?
           <h1 className="modal-title">
             Add New Secretary
           </h1>
           :
           <h1 className="modal-title">
             Update Secretary
           </h1>
           }
          
           <button
             aria-label="Close"
             className="close"
             data-dismiss="modal"
             type="button"
             onClick={() => this.setState({modalsecretary:false})}
           >
             <span aria-hidden={true}>×</span>
           </button>
         </div>
         <div className="modal-body p-10">
       {/*Body start here */}
       {/*Body start here */}
       {/*Body start here */}

       <div>
         {/* Table */}
         <Row>
           <div className=" col">           
             {
             /* Success Status*/
             this.state.aftersubmitstatus == 1
             ?             
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
               <img 
               className="img-quotationlogodoneneweditrot"
               width="120"
               height="120"
               alt="..."
               src={require("./../../assets/img/brand/logomewah4.png")}
             />
             <p className="word-quotationaddnewfinish" >Thanks for choosing Mewahtrans Logistics,</p>
             <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
             <ReactToPrint
         trigger={() =>   <Button
           color="primary"
           data-dismiss="modal"
           type="button"
           to="/admin/orders"
           >
           Print Rot
           </Button>}
         content={() => this.componentRef}
       />
           <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             onClick={() => {this.closeandrefreshaftersubmit()}}
             >
             Close
           </Button>
         
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Fail Status */
             this.state.aftersubmitstatus == 2
             ?              
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
             <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
             <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             className="buttonmiddle"
             onClick={() => this.setState({aftersubmitstatus:0})}
             >
             Retry
           </Button>
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Loading Status */
             this.state.aftersubmitstatus == 3
             ?
             <Row >
               <Col md="12" >
                 <img 
                 className="img-center"
                 width="120"
                 height="120"
                 alt="..."
                 src={require("./../../assets/gif/lorrygif3.gif")}
               />
               </Col>
           </Row>
             :             
             <Form>
          

                 {this.state.errorsubmit == true ?
                    <Alert color="danger">
                    {this.state.alerterrormessage}
                  </Alert>
                  :
                  null
                  }

                   {/*Secretary Name, ID*/}
                   <Row className="mt-5">
                   <Col md="6">
                       <p className="h5">Secretary Name:</p>
                       <FormGroup>
                         <Input
                           placeholder="Secretary Name"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>    
                     <Col md="6">
                       <p className="h5">Secretary ID:</p>
                       <FormGroup>
                         <Input
                           placeholder="Secretary ID"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                                 
                 </Row>
              
                 {/*Company name , registerno*/}
                 <Row className="mt-1">
                   <Col md="6">
                       <p className="h5">Secretary Company Name:</p>
                       <FormGroup>
                         <Input
                           placeholder="Secretary Company Name"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>    
                     <Col md="6">
                       <p className="h5">Secretary Company Register No:</p>
                       <FormGroup>
                         <Input
                           placeholder="Secretary Company Register No"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                
                 </Row>

                 {/* Secretary Company Address */}
                 <Row className="mt-1">
                     <Col md="12">
                       <p className="h5">Secretary Company Address:</p>
                       <FormGroup>
                         <Input
                           placeholder="Address line 1"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                       <FormGroup>
                         <Input
                           placeholder="Address line 2"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                       <FormGroup>
                         <Input
                           placeholder="Address line 3"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                
                 </Row>
             </Form>
                
             }

                    
        <div style={{ display: "none" }}>
        </div> 
        <Row>
           <Col md="3">
           <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending(this.state.editorderrotnumber)}>
           Submit
         </Button>
           </Col>
         </Row>

           </div>
         </Row>
       </div>
    

       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
      </div> 
      
         </div>}
        </Modal>

        {/*Modal Audit*/}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.modalaudit}
          toggle={() => this.setState({modalaudit:false})}
        >
          {this.state.loadingmodaleditorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
         <div>
         <div className="modal-header">
           {this.state.addneworedituser == 1
           ?
           <h1 className="modal-title">
             Add New Audit
           </h1>
           :
           <h1 className="modal-title">
             Update Audit
           </h1>
           }
          
           <button
             aria-label="Close"
             className="close"
             data-dismiss="modal"
             type="button"
             onClick={() => this.setState({modalaudit:false})}
           >
             <span aria-hidden={true}>×</span>
           </button>
         </div>
         <div className="modal-body p-10">
       {/*Body start here */}
       {/*Body start here */}
       {/*Body start here */}

       <div>
         {/* Table */}
         <Row>
           <div className=" col">           
             {
             /* Success Status*/
             this.state.aftersubmitstatus == 1
             ?             
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
               <img 
               className="img-quotationlogodoneneweditrot"
               width="120"
               height="120"
               alt="..."
               src={require("./../../assets/img/brand/logomewah4.png")}
             />
             <p className="word-quotationaddnewfinish" >Thanks for choosing Mewahtrans Logistics,</p>
             <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
             <ReactToPrint
         trigger={() =>   <Button
           color="primary"
           data-dismiss="modal"
           type="button"
           to="/admin/orders"
           >
           Print Rot
           </Button>}
         content={() => this.componentRef}
       />
           <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             onClick={() => {this.closeandrefreshaftersubmit()}}
             >
             Close
           </Button>
         
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Fail Status */
             this.state.aftersubmitstatus == 2
             ?              
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
             <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
             <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             className="buttonmiddle"
             onClick={() => this.setState({aftersubmitstatus:0})}
             >
             Retry
           </Button>
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Loading Status */
             this.state.aftersubmitstatus == 3
             ?
             <Row >
               <Col md="12" >
                 <img 
                 className="img-center"
                 width="120"
                 height="120"
                 alt="..."
                 src={require("./../../assets/gif/lorrygif3.gif")}
               />
               </Col>
           </Row>
             :             
             <Form>
          

                 {this.state.errorsubmit == true ?
                    <Alert color="danger">
                    {this.state.alerterrormessage}
                  </Alert>
                  :
                  null
                  }

                   {/*Secretary Name, ID*/}
                   <Row className="mt-5">
                   <Col md="6">
                       <p className="h5">Audit Name:</p>
                       <FormGroup>
                         <Input
                           placeholder="Audit Name"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>    
                     <Col md="6">
                       <p className="h5">Audit ID:</p>
                       <FormGroup>
                         <Input
                           placeholder="Audit ID"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                                 
                 </Row>
              
                 {/*Company name , registerno*/}
                 <Row className="mt-1">
                   <Col md="6">
                       <p className="h5">Audit Company Name:</p>
                       <FormGroup>
                         <Input
                           placeholder="Audit Company"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>    
                     <Col md="6">
                       <p className="h5">Audit Company Register No:</p>
                       <FormGroup>
                         <Input
                           placeholder="Audit Company Register No"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                
                 </Row>

                 {/* Secretary Company Address */}
                 <Row className="mt-1">
                     <Col md="12">
                       <p className="h5">Audit Company Address:</p>
                       <FormGroup>
                         <Input
                           placeholder="Address line 1"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                       <FormGroup>
                         <Input
                           placeholder="Address line 2"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                       <FormGroup>
                         <Input
                           placeholder="Address line 3"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                
                 </Row>
             </Form>
                
             }

                    
        <div style={{ display: "none" }}>
        </div> 
        <Row>
           <Col md="3">
           <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending(this.state.editorderrotnumber)}>
           Submit
         </Button>
           </Col>
         </Row>

           </div>
         </Row>
       </div>
    

       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
      </div> 
      
         </div>}
        </Modal>

        {/*Modal Tax*/}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.modaltax}
          toggle={() => this.setState({modaltax:false})}
        >
          {this.state.loadingmodaleditorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
         <div>
         <div className="modal-header">
           {this.state.addneworedituser == 1
           ?
           <h1 className="modal-title">
             Add New Tax
           </h1>
           :
           <h1 className="modal-title">
             Update Tax
           </h1>
           }
          
           <button
             aria-label="Close"
             className="close"
             data-dismiss="modal"
             type="button"
             onClick={() => this.setState({modaltax:false})}
           >
             <span aria-hidden={true}>×</span>
           </button>
         </div>
         <div className="modal-body p-10">
       {/*Body start here */}
       {/*Body start here */}
       {/*Body start here */}

       <div>
         {/* Table */}
         <Row>
           <div className=" col">           
             {
             /* Success Status*/
             this.state.aftersubmitstatus == 1
             ?             
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
               <img 
               className="img-quotationlogodoneneweditrot"
               width="120"
               height="120"
               alt="..."
               src={require("./../../assets/img/brand/logomewah4.png")}
             />
             <p className="word-quotationaddnewfinish" >Thanks for choosing Mewahtrans Logistics,</p>
             <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
             <ReactToPrint
         trigger={() =>   <Button
           color="primary"
           data-dismiss="modal"
           type="button"
           to="/admin/orders"
           >
           Print Rot
           </Button>}
         content={() => this.componentRef}
       />
           <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             onClick={() => {this.closeandrefreshaftersubmit()}}
             >
             Close
           </Button>
         
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Fail Status */
             this.state.aftersubmitstatus == 2
             ?              
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
             <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
             <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             className="buttonmiddle"
             onClick={() => this.setState({aftersubmitstatus:0})}
             >
             Retry
           </Button>
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Loading Status */
             this.state.aftersubmitstatus == 3
             ?
             <Row >
               <Col md="12" >
                 <img 
                 className="img-center"
                 width="120"
                 height="120"
                 alt="..."
                 src={require("./../../assets/gif/lorrygif3.gif")}
               />
               </Col>
           </Row>
             :             
             <Form>
          

                 {this.state.errorsubmit == true ?
                    <Alert color="danger">
                    {this.state.alerterrormessage}
                  </Alert>
                  :
                  null
                  }

                   {/*Tax Name, ID*/}
                   <Row className="mt-5">
                   <Col md="6">
                       <p className="h5">Tax Name:</p>
                       <FormGroup>
                         <Input
                           placeholder="Tax Name"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>    
                     <Col md="6">
                       <p className="h5">Tax ID:</p>
                       <FormGroup>
                         <Input
                           placeholder="Tax ID"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                                 
                 </Row>
              
                 {/*Company name , registerno*/}
                 <Row className="mt-1">
                   <Col md="6">
                       <p className="h5">Tax Company Name:</p>
                       <FormGroup>
                         <Input
                           placeholder="Tax Company Name"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>    
                     <Col md="6">
                       <p className="h5">Tax Company Register No:</p>
                       <FormGroup>
                         <Input
                           placeholder="Tax Company Register No"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                
                 </Row>

                 {/* Tax Company Address */}
                 <Row className="mt-1">
                     <Col md="12">
                       <p className="h5">Tax Company Address:</p>
                       <FormGroup>
                         <Input
                           placeholder="Address line 1"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                       <FormGroup>
                         <Input
                           placeholder="Address line 2"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                       <FormGroup>
                         <Input
                           placeholder="Address line 3"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                
                 </Row>
             </Form>
                
             }

                    
        <div style={{ display: "none" }}>
        </div> 
        <Row>
           <Col md="3">
           <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending(this.state.editorderrotnumber)}>
           Submit
         </Button>
           </Col>
         </Row>

           </div>
         </Row>
       </div>
    

       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
      </div> 
      
         </div>}
        </Modal>

        {/*Modal Template*/}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.modaltemplate}
          toggle={() => this.setState({modaltemplate:false})}
        >
          {this.state.loadingmodaleditorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
         <div>
         <div className="modal-header">
           {this.state.addneworedituser == 1
           ?
           <h1 className="modal-title">
             Add New Template
           </h1>
           :
           <h1 className="modal-title">
             Update Template
           </h1>
           }
          
           <button
             aria-label="Close"
             className="close"
             data-dismiss="modal"
             type="button"
             onClick={() => this.setState({modaltemplate:false})}
           >
             <span aria-hidden={true}>×</span>
           </button>
         </div>
         <div className="modal-body p-10">
       {/*Body start here */}
       {/*Body start here */}
       {/*Body start here */}

       <div>
         {/* Table */}
         <Row>
           <div className=" col">           
             {
             /* Success Status*/
             this.state.aftersubmitstatus == 1
             ?             
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
               <img 
               className="img-quotationlogodoneneweditrot"
               width="120"
               height="120"
               alt="..."
               src={require("./../../assets/img/brand/logomewah4.png")}
             />
             <p className="word-quotationaddnewfinish" >Thanks for choosing Mewahtrans Logistics,</p>
             <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
             <ReactToPrint
         trigger={() =>   <Button
           color="primary"
           data-dismiss="modal"
           type="button"
           to="/admin/orders"
           >
           Print Rot
           </Button>}
         content={() => this.componentRef}
       />
           <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             onClick={() => {this.closeandrefreshaftersubmit()}}
             >
             Close
           </Button>
         
           </Col>
           
         </Row>
         </Form>
            </div>
             :
             /* Fail Status */
             this.state.aftersubmitstatus == 2
             ?              
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
             <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
             <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             className="buttonmiddle"
             onClick={() => this.setState({aftersubmitstatus:0})}
             >
             Retry
           </Button>
           </Col>
           
         </Row>
         </Form>
            </div>
             :
             /* Loading Status */
             this.state.aftersubmitstatus == 3
             ?
             <Row >
               <Col md="12" >
                 <img 
                 className="img-center"
                 width="120"
                 height="120"
                 alt="..."
                 src={require("./../../assets/gif/lorrygif3.gif")}
               />
               </Col>
           </Row>
             :             
             <Form>
                 {this.state.errorsubmit == true ?
                    <Alert color="danger">
                    {this.state.alerterrormessage}
                  </Alert>
                  :
                  null
                  }

                   {/*Report Name, ID,category*/}
                   <Row className="mt-5">
                   <Col md="4">
                       <p className="h5">Report Name:</p>
                       <FormGroup>
                         <Input
                           placeholder="Report Name"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>    
                     <Col md="4">
                       <p className="h5">Report Category:</p>
                       <FormGroup>
                         <Input
                           placeholder="Report Category"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col> 
                     <Col md="4">
                       <p className="h5">Report ID:</p>
                       <FormGroup>
                         <Input
                           placeholder="Report ID"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                                   
                 </Row>
              
                 {/*Variable button*/}
                 <Row className="mt-1">
                   <Col md="10">
                   <p>Variable:</p>
                   </Col>
                   <Col md="1">
                   <Button block  color="primary" type="button" onClick={()=>this.addvolumebuttonclicked()}>
                          +
                      </Button>                    
                   </Col>
                   <Col md="1">
                   <Button block  color="primary" type="button" onClick={()=>this.minusvolumebuttonclicked()}>
                          -
                      </Button>                    
                   </Col>
                
                 
                 </Row>

                 {/* variable loop */}
                  <Row>
                  <Col md="6">                       
                       <FormGroup>
                         <Input
                           placeholder="Variable ID"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>
                     <Col md="6">                       
                       <FormGroup>
                         <Input
                           placeholder="Variable Data"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>
                  </Row>
               
                    {this.rendervariablesection()}
                  


             
             </Form>                
             }

                    
        <div style={{ display: "none" }}>
        </div> 
        <Row>
           <Col md="3">
           <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending(this.state.editorderrotnumber)}>
           Submit
         </Button>
           </Col>
         </Row>

           </div>
         </Row>
       </div>
    

       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
      </div> 
      
         </div>}
        </Modal>

      
      </>
    );
  }
}

export default Masterfile;
