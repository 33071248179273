/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
* npm run build
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar,Doughnut,Pie  } from "react-chartjs-2";
import classnames from "classnames";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.jsx";
//import Moment from 'moment';
// reactstrap components
import { Card,
  Alert,
  Modal,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Form,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
  FormFeedback,

  Button,} from "reactstrap";
  import ReactToPrint from 'react-to-print';
  import { Link } from 'react-router-dom';
// core components
import ReactDatetime from "react-datetime";
import Header from "components/Headers/Header.jsx";
import Footer from "components/Footers/AdminFooter.jsx";
import { textChangeRangeIsUnchanged } from "typescript";
import axios ,{post} from 'axios'; 
import Moment from 'moment';

class Reports extends React.Component {

  constructor(props){
    super(props);
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
    }
  }

  state = {    
    tabs: 1,
    tabsimportexport:1,    
    attachmentlist:[],
    rotpendinglist:[],
    searchtextvalue:'',
    linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"http://crm.mewahtrans.com.my/api/img/profile_img/",
  };

  


  componentDidMount(){
   // this.getpendingorderlistapi("Import");
   document.addEventListener('keyup', (e) => {
    if (e.keyCode === 27){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.cancelledsearchbuttonclick()
      }
     
    }else if(e.keyCode === 13){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.searchfunctionstart()
      }
      
    }
    },false);
    this.setState({searchtextvalue:""})
    this.setState({buttoncolor2:"primary"})
 //  this.setdatedefaultincomplete();
   // this.getcreatedbywho();
   // this.getorderlistapi("Import");
    this.setState({tabsimportexport:1})
    this.setState({attachmentsetID:Moment().unix()}) ;   
  }

  orderbuttonclicked = () =>{
    this.setState({buttoncolor1:"white"})
    this.setState({buttoncolor2:"primary"})
    this.setState({buttoncolor3:"white"})
    this.setState({tabs:2})
    this.setState({tabsimportexport:1})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})
    this.getorderlistapi("Import");
  
  }

  cancelledsearchbuttonclick = () =>{
    if(this.state.tabs == 1){
     this.setState({searchtextvalue:""})
     this.setState({currentcompletedpagenumber:1})
     if(this.state.tabsimportexport == 1){
      this.getpendingorderlistapi("Import");
     }else{
      this.getpendingorderlistapi("Export");
     }
     
    }else if( this.state.tabs ==2){
     this.setState({searchtextvalue:""})
     if(this.state.tabsimportexport == 1){
      this.getorderlistapi("Import");
     }else{
      this.getorderlistapi("Export");
     }
    }else if( this.state.tabs ==3){
      this.setState({searchtextvalue:""})
      if(this.state.tabsimportexport == 1){
        this.getcompletedorderapiwithdaterange("Import");
       }else{
        this.getcompletedorderapiwithdaterange("Export");
       }
     }      
  }

  nextpaginationfunction = (e) =>{
    if(this.state.completelist.length/100 <= this.state.currentcompletedpagenumber){
      
    }else{
     this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber +1})
    }
  }
 
  previouspaginationfunction = (e) =>{
   if(this.state.currentcompletedpagenumber== 1){
     
   }else{
    this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber - 1})
   }
  }

  renderTablePagination() {
         
      { if( this.state.completelist.length/100 < 1){
        return( null )
      }else if(this.state.completelist.length/100 >= 1 && this.state.completelist.length/100 < 2 ){
       return (
         
            
          <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                           <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                            <PaginationLink href="#pablo" onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>      
                 <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
                  
                 )
      }else if(this.state.completelist.length/100 >= 2 && this.state.completelist.length/100 < 3 ){
       return (
          
               
             <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>      
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               
             
            


                 )
      }else if(this.state.completelist.length/100 >= 3 && this.state.completelist.length/100 < 4 ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                       <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber ==2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>          
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      }else if(this.state.completelist.length/100 >= 4 && this.state.completelist.length/100 < 5 ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>   
                          <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                              5
                            </PaginationLink>
                          </PaginationItem>           
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      }else if(this.state.completelist.length/100 >= 5  ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                    <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>   
                          <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                              5
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 6 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:6})}>
                              ...
                            </PaginationLink>
                          </PaginationItem>           
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      } 
      
     }
  }


  renderincorporation() {
      
    return this.state.rotpendinglist.map((data, index) => {
       const {createdby,createdbywhoref,importexport, no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
       var rotlength = Object.keys(this.state.rotpendinglist).length;
       var volumeshow = totalvolume.slice(0,35)
       return (
    
            
            <tbody>
              
             <tr>
   
            <td>{no})</td>
              <td >{rotno}</td>
              <td >{importexport}</td>
                  <td >
                
                        <span className="mb-0 text-sm">
                          {shippername}
                        </span>
                    
                  </td>
                  <td>{volumeshow}</td>
                  <td>{bookingno}</td>    
                  <td>
                    <Badge color="" className="badge-dot mr-4">
                        {status =="Pending"                
                        ?
                        <i className="bg-info" />
                        :
                        <i className="bg-warning" />
                      }
                      
                        {status}
                      </Badge>
                  </td>  
              
                  <td>
                          <i  onClick={(e)=>this.expandbuttonclickpending(no,rotno,status,rotlength)} className="ni ni-bold-down" />          
                        </td>
                  </tr>    
                  {this.state["expandpendingimport"+no] == false?
                  null:
                  <tr>
                  <td colspan="8">   
               
                    {this.renderinsideexpandpending(no,createdby,createdbywhoref,rotno,status)}
                
                </td>
                </tr>
                }
                </tbody>

       
       
   
       )
    })
  }

  renderPendingOrderDataphone2() {
      
    return this.state.rotpendinglist.map((data, index) => {
       const {createdby,createdbywhoref,importexport, no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
       var rotlength = Object.keys(this.state.rotpendinglist).length;
       var volumeshow = totalvolume.slice(0,35)
       return (
             <div className="mt-1 cardviewbox"> 
              <div className="horizontaldirection">
                <h4 className="ml-4 mr-10 phoneviewcontent">{shippername}</h4>
                <p className="ml-auto mr-20 phoneviewcontent">
                <i  onClick={(e)=>this.expandbuttonclickpending(no,rotno,status,rotlength)} className="ni ni-bold-down" />                   
                          </p>
                </div> 
                <p className="ml-4 mr-10 phoneviewcontent mt-2">{rotno}</p>                  
                <p className="ml-4 mr-10 phoneviewcontent mt-2">{importexport}</p>    
                <p className="ml-4 mr-10 phoneviewcontent mt-2">{volumeshow}</p>
                <p className="ml-4 mr-10 phoneviewcontent mt-2">{bookingno}</p>
                <p className="ml-4 mr-10 phoneviewcontent mt-2">
                    <Badge color="" className="badge-dot mr-4">
                                  {status =="Pending"                
                                  ?
                                  <i className="bg-info" />
                                  :
                                  <i className="bg-warning" />
                                }
                                
                                  {status}
                      </Badge>
                  </p>                             
                        {this.state["expandpendingimport"+no] == false?
                        null:
                                <div>
                            {this.renderinsideexpandpending(no,createdby,createdbywhoref,rotno,status)}
                      </div>                   
                        
                }
                </div>

       
       
   
       )
    })
  }

  handleKeyPress = (event) => {
    if(event.key === 'Escape'){
      console.log('Escape press here! ')
    }
    if(event.keyCode === 27){
      event.preventDefault();
    }
    console.log(event.key)
    console.log(event.keyCode)
  }
  
  render() {
    return (
      <>
        <Header />
        {/* Page contentas */}
        <Container className=" mt--10" fluid>
          {/* Table */}
          <Row>
            <div className=" col-12 ">
          
        <Col md="12  mt-3">
        {window.innerWidth <= 760 ?
                <div className="d-md-inline-block">
             
                <NavLink className="nav-link-icon d-md-inline-block col-12">
              <Button  className={classnames("col-12")} color={this.state.buttoncolor2} type="button"  onClick={(e)=>this.orderbuttonclicked()}>
               <i className="ni ni-collection  mr-2" />
                 Incorporation
              </Button>
              </NavLink>
              
                </div>
         :
<div>
      
         <div className="d-md-inline-block mt-6">
         
     
         </div>
  
        
       </div>
        }

                
        </Col>
            </div>

            
            <div className=" col-12 ">
              <Card className=" shadow  ">
              

            
            
              {this.state.loading
              ?
              <Row >
                <Col md="12" >
              <img 
              className="img-center mt-5"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
              :
              <CardBody >
                
                <Row>
              <Col md="12">
                <p className="h5">Company Name:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Company Name 1"
                    type="text"
                    value={this.state.companynamereport}
                  />
                </FormGroup>

                <p className="h5">Report:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Report Template"
                    type="text"
                    value={this.state.reporttemplate1}
                  />
                </FormGroup>

               
            
              </Col>              
            </Row>

            {/*Date from or To  */}
            <Row>
                <Col md="6">
                <p className="h5">From Date:</p>
                <FormGroup>
                  <Input                  
                    placeholder="From Date"
                    type="text"
                    value={this.state.parameter1}
                  />
                </FormGroup>
                </Col> 
                <Col md="6">
                <p className="h5">To Date:</p>
                <FormGroup>
                  <Input                  
                    placeholder="To Date"
                    type="text"
                    value={this.state.parameter1}
                  />
                </FormGroup>
                </Col> 
           </Row>
            
            {/*button submit */}
            <Row>
                <Col md="12">
                <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename3, "_blank")}>
                           Generate
                       </Button> 
                       </Col>  
            </Row>
                
        <Row className="mt-5">
           {/* Top Shipper vs container */}
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">                     
                      <h2 className="text-white mb-0">Total Share</h2>
                    </div>
                    {/* <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1
                            })}
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 1)}
                          >
                            <span className="d-none d-md-block">Month</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 2
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 2)}
                          >
                            <span className="d-none d-md-block">Week</span>
                            <span className="d-md-none">W</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Line
                      data={this.state.datacontainertotalbymonth}
                      options={chartExample1.options}                      
                      getDatasetAtEvent={e => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

        

        </Row>

              </CardBody>
                  }

                  
             
                
              </Card>
            </div>
          </Row>
          
        </Container>     
       

        
      </>
    );
  }
}



export default Reports;
