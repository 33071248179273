/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
* npm run build
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import classnames from "classnames";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
//import Moment from 'moment';
// reactstrap components
import { Card,
  Alert,
  Modal,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Form,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
  FormFeedback,

  Button,} from "reactstrap";
  import ReactToPrint from 'react-to-print';
  import { Link } from 'react-router-dom';
// core components
import ReactDatetime from "react-datetime";
import Header from "components/Headers/Header.jsx";
import Footer from "components/Footers/AdminFooter.jsx";
import { textChangeRangeIsUnchanged } from "typescript";
import axios ,{post} from 'axios'; 
import Moment from 'moment';

class Task extends React.Component {

  constructor(props){
    super(props);
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
    }
  }

  state = {    
    tabs: 1,
    tabsimportexport:1,    
    attachmentlist:[],
    rotpendinglist:[],
    searchtextvalue:'',
    yearofmbrs:2022,
    modaltaskshow:false,
    linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"http://crm.mewahtrans.com.my/api/img/profile_img/",
  };

  


  componentDidMount(){
   // this.getpendingorderlistapi("Import");
   document.addEventListener('keyup', (e) => {
    if (e.keyCode === 27){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.cancelledsearchbuttonclick()
      }
     
    }else if(e.keyCode === 13){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.searchfunctionstart()
      }
      
    }
    },false);
    this.setState({searchtextvalue:""})
    this.setState({buttoncolor2:"primary"})
 //  this.setdatedefaultincomplete();
   // this.getcreatedbywho();
   // this.getorderlistapi("Import");
    this.setState({tabsimportexport:1})
    this.setState({attachmentsetID:Moment().unix()}) ;   
  }

  cancelledsearchbuttonclick = () =>{
    if(this.state.tabs == 1){
     this.setState({searchtextvalue:""})
     this.setState({currentcompletedpagenumber:1})
     if(this.state.tabsimportexport == 1){
      this.getpendingorderlistapi("Import");
     }else{
      this.getpendingorderlistapi("Export");
     }
     
    }else if( this.state.tabs ==2){
     this.setState({searchtextvalue:""})
     if(this.state.tabsimportexport == 1){
      this.getorderlistapi("Import");
     }else{
      this.getorderlistapi("Export");
     }
    }else if( this.state.tabs ==3){
      this.setState({searchtextvalue:""})
      if(this.state.tabsimportexport == 1){
        this.getcompletedorderapiwithdaterange("Import");
       }else{
        this.getcompletedorderapiwithdaterange("Export");
       }
     }      
  }

  nextpaginationfunction = (e) =>{
    if(this.state.completelist.length/100 <= this.state.currentcompletedpagenumber){
      
    }else{
     this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber +1})
    }
  }
 
  previouspaginationfunction = (e) =>{
   if(this.state.currentcompletedpagenumber== 1){
     
   }else{
    this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber - 1})
   }
  }

  renderTablePagination() {
         
      { if( this.state.completelist.length/100 < 1){
        return( null )
      }else if(this.state.completelist.length/100 >= 1 && this.state.completelist.length/100 < 2 ){
       return (
         
            
          <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                           <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                            <PaginationLink href="#pablo" onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>      
                 <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
                  
                 )
      }else if(this.state.completelist.length/100 >= 2 && this.state.completelist.length/100 < 3 ){
       return (
          
               
             <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>      
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               
             
            


                 )
      }else if(this.state.completelist.length/100 >= 3 && this.state.completelist.length/100 < 4 ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                       <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber ==2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>          
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      }else if(this.state.completelist.length/100 >= 4 && this.state.completelist.length/100 < 5 ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                     <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>   
                          <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                              5
                            </PaginationLink>
                          </PaginationItem>           
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      }else if(this.state.completelist.length/100 >= 5  ){
       return (
                 <nav aria-label="Page navigation example">
                 <Pagination
                   className="pagination justify-content-end"
                   listClassName="justify-content-end"
                 >
                    <PaginationItem   >
                       <PaginationLink
                         href="#pablo"
                         onClick={(e) => this.previouspaginationfunction()}
                         tabIndex="-1"
                       >
                         <i className="fa fa-angle-left" />
                         <span className="sr-only">Previous</span>
                       </PaginationLink>
                     </PaginationItem>
                     <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                         1
                       </PaginationLink>
                     </PaginationItem>               
                     <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                              2
                            </PaginationLink>
                          </PaginationItem>    
                          <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                              3
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                              4
                            </PaginationLink>
                          </PaginationItem>   
                          <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                              5
                            </PaginationLink>
                          </PaginationItem>  
                          <PaginationItem className={this.state.currentcompletedpagenumber == 6 ? "active" : ""}>
                       <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:6})}>
                              ...
                            </PaginationLink>
                          </PaginationItem>           
                          <PaginationItem>
                         <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                           <i className="fa fa-angle-right" />
                           <span className="sr-only">Next</span>
                         </PaginationLink>
                       </PaginationItem>
                     </Pagination>
                   </nav>                                    
               )
      } 
      
     }
  }


  handleKeyPress = (event) => {
    if(event.key === 'Escape'){
      console.log('Escape press here! ')
    }
    if(event.keyCode === 27){
      event.preventDefault();
    }
    console.log(event.key)
    console.log(event.keyCode)
  }

  toggleModaltask = (state,addneworupdate) => {
    this.setState({modaltaskshow:state})
    if(addneworupdate == 1){
      // addnew
      this.setState({addneworedituser:1})
    }else{
      //update
      this.setState({addneworedituser:2})
    }
   // console.log(state)
  };

  
  render() {
    return (
      <>
       <Header />
        {/* Page contentas */}
        <Container className=" mt--10" fluid>
          {/* Table */}
          <Row>
            {/* top category button */}
            <div className=" col-12 ">         
                <Col md="12  mt-3">      
                <div>                      
                        <div className="d-md-inline-block mt-6">
                    
                   
                        </div>
                  
                        
                 </div>   
                </Col>
            </div>

            {/*Content*/}
            <div className=" col-12 ">
              <Card className=" shadow  ">             
              {this.state.loading
                ?
                // loading
                <Row >
                  <Col md="12" >
                    <img 
                    className="img-center mt-5"
                    width="120"
                    height="120"
                    alt="..."
                    src={require("./../../assets/gif/lorrygif3.gif")}
                  />
                  </Col>
                </Row>
                :
                //All Category Content
                <CardBody >    
                  <TabContent  activeTab={"tabs" + this.state.tabs}>
                    {/* modaltaskshow tab */}
                    <TabPane tabId="tabs1">    
                      {/*Header*/}  
                      <Row>
                          {/*Add new modaltaskshow tab */}
                          <Col >
                            <div className=" col-9 d-md-inline-block ">                            
                            <Button className="col-12" color="primary" type="button" onClick={() => this.toggleModaltask(true,1)}>
                            Add New Task
                            </Button>
                            </div>
                          </Col>

                          {/*Search Bar */}
                          <Col md="8 form-inline  d-md-flex">    
                            {/* Searchbar is here */}                      
                            {window.innerWidth <= 760 ?
                            <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                              
                                  <i className="fas fa-search" />
                                
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                            {this.state.searchtextvalue == "" ?
                            null:
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                              
                            <Button className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</Button >
                              
                            </InputGroupText>
                          </InputGroupAddon>
                            }
                                
                                
                              
                            </InputGroup>
                            :
                            <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                
                                  <i className="fas fa-search" />
                                  
                                </InputGroupText>
                              </InputGroupAddon>
                            
                                <Input
                                  placeholder="Search" 
                                  value={this.state.searchtextvalue}
                                  onChange = {(event) => this.setState({ searchtextvalue: event.target.value })}
                                  type="text"
                                  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                                />
                              
                              {this.state.searchtextvalue == "" ?
                              null:
                              <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                              <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                              
                            <span className="buttonsearch" onClick={(e)=>this.searchfunctionstart()}>Search</span >
                                
                              </InputGroupText>
                            </InputGroupAddon>
                              }
                                
                                  
                              
                            </InputGroup>
                            }
                          </Col>
                      </Row>   

                      {/*Table Content*/}
                      <Table className="align-items-center" responsive>
                      <thead className="thead-light">
                          <tr>
                            <th>No.</th>
                            <th>ID.</th>
                            <th>Company Name</th>
                            <th>Job Type</th>
                            <th>Report ID / Link</th>
                            <th>Date to Complete</th>                         
                            <th >Status</th>
                            <th >Action</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                            
                        <tbody>              
                          <tr>    
                            <td>1)</td>
                            <td>TASK000001</td>   
                            <td>ABC Company</td>
                            <td>Digital Siging</td>
                            <td>REPORT123000</td>  
                            <td>20/02/2022</td>   
                            <td >
                         <Badge color="" className="badge-dot mr-4">
                                  <i className="bg-info" />     
                                Pending
                      </Badge>
                            </td>
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    Change to Pending
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    Change to Completed
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
        
                          </tr> 
                          <tr>    
                            <td>2)</td>
                            <td>TASK000002</td>   
                            <td>DEF Company</td>
                            <td>Approve Document</td>
                            <td>REPORT123003</td>  
                            <td>28/02/2022</td>   
                            <td >
                         <Badge color="" className="badge-dot mr-4">
                                  <i className="bg-success" />     
                                Completed
                      </Badge>
                            </td>
                            <td className="text-middle">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                            
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    View/Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    Change to Pending
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    
                                    // onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                                  >
                                    Change to Completed
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    // onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                                  >
                                    Delete
                                  </DropdownItem>
                                
                                </DropdownMenu>
                                
                                
                              </UncontrolledDropdown>
                            </td>
        
                          </tr> 
                        </tbody>
                                        
                      </Table>  
                    </TabPane>
                  </TabContent>
                </CardBody>
              }
              </Card>
            </div>
          </Row>
          
        </Container>     
       
 {/*Modal modaltransactionshow*/}
 <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.modaltaskshow}
          toggle={() => this.setState({modaltaskshow:false})}
        >
          {this.state.loadingmodaleditorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>
         :
         <div>
         <div className="modal-header">
         
         {this.state.addneworedituser == 1
           ?
           <h1 className="modal-title">
             Add New Task
           </h1>
           :
           <h1 className="modal-title">
             Update Task
           </h1>
           }      
          
           <button
             aria-label="Close"
             className="close"
             data-dismiss="modal"
             type="button"
             onClick={() => this.setState({modaltaskshow:false})}
           >
             <span aria-hidden={true}>×</span>
           </button>
         </div>
         <div className="modal-body p-10">
       {/*Body start here */}
       {/*Body start here */}
       {/*Body start here */}

       <div>
         {/* Table */}
         <Row>
           <div className=" col">           
             {
             /* Success Status*/
             this.state.aftersubmitstatus == 1
             ?             
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
               <img 
               className="img-quotationlogodoneneweditrot"
               width="120"
               height="120"
               alt="..."
               src={require("./../../assets/img/brand/logomewah4.png")}
             />
             <p className="word-quotationaddnewfinish" >Thanks for choosing Mewahtrans Logistics,</p>
             <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
             <ReactToPrint
         trigger={() =>   <Button
           color="primary"
           data-dismiss="modal"
           type="button"
           to="/admin/orders"
           >
           Print Rot
           </Button>}
         content={() => this.componentRef}
       />
           <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             onClick={() => {this.closeandrefreshaftersubmit()}}
             >
             Close
           </Button>
         
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Fail Status */
             this.state.aftersubmitstatus == 2
             ?              
             <div className="modalforquotationaddnew">
         <Form>
           <Row>
           <Col md="12">
             <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
             <Button
             color="primary"
             data-dismiss="modal"
             type="button"
             className="buttonmiddle"
             onClick={() => this.setState({aftersubmitstatus:0})}
             >
             Retry
           </Button>
           </Col>
           
         </Row>
         </Form>
        </div>
             :
             /* Loading Status */
             this.state.aftersubmitstatus == 3
             ?
             <Row >
               <Col md="12" >
                 <img 
                 className="img-center"
                 width="120"
                 height="120"
                 alt="..."
                 src={require("./../../assets/gif/lorrygif3.gif")}
               />
               </Col>
           </Row>
             :             
             <Form>
          

                 {this.state.errorsubmit == true ?
                    <Alert color="danger">
                    {this.state.alerterrormessage}
                  </Alert>
                  :
                  null
                  }

                   {/*Company Name */}
                   <Row className="mt-5">
                   <Col md="12">
                       <p className="h5">Company Name:</p>
                       <FormGroup>
                         <Input
                           placeholder="Company Name"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                     
                 </Row>       

                   {/*Job Type*/}
                    <Row>                   
                     <Col md="12">
                       <p className="h5">Job Type:</p>
                       <FormGroup>
                         <Input
                           placeholder="Job Type"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                                 
                 </Row>    

                  <Row>                   
                     <Col md="12">
                       <p className="h5">Report ID / Link:</p>
                       <FormGroup>
                         <Input
                           placeholder="Report ID / Link"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                                 
                 </Row> 

                 <Row>                   
                     <Col md="12">
                       <p className="h5">Date to Completed:</p>
                       <FormGroup>
                         <Input
                           placeholder="Date to Completed"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                                 
                 </Row>  

                  <Row>                   
                     <Col md="12">
                       <p className="h5">Status:</p>
                       <FormGroup>
                         <Input
                           placeholder="Status"
                           value={this.state.Billtoparty}
                           type="text"
                           disabled
                         />
                       </FormGroup>
                     </Col>                                 
                 </Row>                
                                 
                   
             </Form>
                
             }

                    
        <div style={{ display: "none" }}>
        </div> 
        <Row>
           <Col md="3">
           <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending(this.state.editorderrotnumber)}>
           Submit
         </Button>
           </Col>
         </Row>

           </div>
         </Row>
       </div>
    

       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
       {/*Body  end here */}
      </div> 
      
         </div>}
        </Modal>

        
      </>
    );
  }
}



export default Task;
