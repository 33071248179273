/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
  Modal,
} from "reactstrap";
// core components
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import UserHeader from "components/Headers/UserHeader.jsx";
import axios ,{post} from 'axios'; 


class Profile extends React.Component {
  state = {
    isopendropdowndirect:false,
    directdropdowntext:'Forwarder',
    name:sessionStorage.getItem('Name'),
    ref:window.sessionStorage.getItem('Ref'),
    profileimageurl:sessionStorage.getItem('Profilepictureurl'),
    Level:sessionStorage.getItem('Level'),
    Billtoparty:window.sessionStorage.getItem('Billtoparty'),
    Directorforwarder:sessionStorage.getItem('Directorforwarder'),
    Email:window.sessionStorage.getItem('Email'),
    Contact:sessionStorage.getItem('Contact'),
    usernameinput:sessionStorage.getItem('Username'),
   linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
   // imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"http://crm.mewahtrans.com.my/api/img/profile_img/",
    password:'',
    confirmpassword:'',
    defaultModal: false,
    onchangeprofilepicture:'',
    
  };
 
  
  toggledropdowndirect =  state => {
    this.setState({
      [state]:!this.state[state]
    });
  };
  
  componentDidMount(){
    if(this.state.Directorforwarder == 1){
      this.setState({directdropdowntext:'Username'})
    }else{
      this.setState({directdropdowntext:'Username'})
    }
    this.setState({onchangeprofilepicture:this.state.imageurllink + this.state.profileimageurl})
    
  }

  updateprofilepassword = () =>{
    if(window.confirm('Are you sure you want to update your contact and password?')){
      if(this.state.password != this.state.confirmpassword){
        window.confirm('Your confirm password no match password')     
      }else{
        var urlencoded = new URLSearchParams();
        urlencoded.append("billtoparty", this.state.Billtoparty);
        urlencoded.append("pic", this.state.name);
        urlencoded.append("Email", this.state.Email);
        urlencoded.append("Passwordtoreset", this.state.password);
        urlencoded.append("contactnumber", this.state.Contact);
        let link = this.state.linkurl + 'mode=admin-updateprofilepassword'
       
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            window.confirm('Your profile update success')
            
             

             
            
            }        
               
         else{
          window.confirm('Your profile update fail, please contact admin to check')
        console.log(json)
      }
    
     })



      }
     
    }
   
 
   
    
  }

  startuploadsaveprofileimage = ()=>{
    //update database profile image name
    //reload sessioncache
    if(window.confirm("Are you sure you want use this picture as your profile picture?")){
      if(Object.keys(this.state.onchangeprofilepicture.name).length < 25){
        const formData = new FormData();    
   
        console.log("formData: " + formData)
        console.log("onchangeprofilepicture: " + this.state.onchangeprofilepicture)
        // Update the formData object 
          if(this.state.onchangeprofilepicture !=null){
            formData.append( 
              "myFile1", 
              this.state.onchangeprofilepicture,
            );
          }
          formData.append( 
            "Refid", 
            this.state.ref
          );
    
          const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
      
      
          return post(this.state.linkurl+"mode=admin-uploadupdateprofileimage", formData,config )
          .then(res => {
  
            window.confirm(res.data.message);       
            sessionStorage.setItem('Profilepictureurl', res.data.updatelink);
            this.setState({profileimageurl:res.data.updatelink})
          });
      }else{
        window.confirm("Filename too long, please change your file name");     
      }
      
    }
   
  }


  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };


  render() {
   const imgurl = "https://cdn.britannica.com/w:400,h:300,c:crop/89/149189-050-68D7613E/Bengal-tiger.jpg"


    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-3" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          width="250"
                          height="200"
                          className="rounded-circle"
                          src={imgurl}
                          onClick={() => this.toggleModal("defaultModal")}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                   
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="heading"></span>
                          <span className="description"></span>
                        </div>
                        <div>
                          <span className="heading"></span>
                          <span className="description"></span>
                        </div>
                        <div>
                          <span className="heading"></span>
                          <span className="description"></span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      {this.state.name}
                      
                    </h3>
                  
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      {this.state.Level}
                    </div>
                    
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                   
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User Information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                        <FormGroup>
                  {/* <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="bg-white ">
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input className="pl-2" value={this.state.usernameinput} placeholder="Username" type="text" disabled />
                  </InputGroup> */}
                </FormGroup>
               
            
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="bg-white" >
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input className="pl-2" value={this.state.Billtoparty} placeholder="User ID" type="text" disabled />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="bg-white">
                        <i className="ni ni-archive-2" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input className="pl-2" value={this.state.directdropdowntext} placeholder="Username" type="text"  disabled/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="bg-white">
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input className="pl-2" value={this.state.name} placeholder="Phone Number" type="text"  disabled/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3 ">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="bg-white">
                        <i className="ni ni-email-83 "  />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input className="pl-2" value={this.state.Email} placeholder="Email" type="email"  disabled/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText >
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input className="pl-2" onChange={(e) => this.setState({Contact:e.target.value})} value={this.state.Contact} placeholder="Contact" type="text" />
                  </InputGroup>
                </FormGroup>
              
                   </Col>
                   </Row>
                   <Button
                  color="primary"
                  data-dismiss="modal"
                  type="button"
                  onClick={(e) => this.updateprofilepassword()}
                  >
                  Update Profile
                </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      
        <Modal
              className="modal-dialog-centered"
              isOpen={this.state.defaultModal}
              toggle={() => this.toggleModal("defaultModal")}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                  Edit Profile Picture
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
             
              {/* <Row>
                               <Col md="12">
                          
                                <img
                                width="100px"
                                height="100px"
                                  alt="..."
                                  className="rounded-circle img-center"
                                  //src={this.state.onchangeprofilepicture}
                                  src={require('D:\self work\pumpkin.jpg')}
                                />
                            
                               </Col>
                              
                               </Row> */}
                               <Row>
                               <Col md="12">      
                                                    
                           <Input className="mt-2 rowbackgroundcolorinsideexpand img-center" accept=".jpg,.png" type="file" name="file"  id="exampleFile" onChange={(e) => this.setState({onchangeprofilepicture:e.target.files[0]})} />                         
                             </Col>
                                 
                                 </Row>

              </div>
              <div className="modal-footer">
                <Button color="primary" type="button" onClick={() => this.startuploadsaveprofileimage()}>
                  Save changes
                </Button>
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  Close
                </Button>
              </div>
            </Modal>
      
      </>



    );
  }
}

export default Profile;
