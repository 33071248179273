/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
* npm run build
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import classnames from "classnames";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
//import Moment from 'moment';
// reactstrap components
import { Card,
  Alert,
  Modal,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Form,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
  FormFeedback,

  Button,} from "reactstrap";
  import ReactToPrint from 'react-to-print';
  import { Link } from 'react-router-dom';
// core components
import ReactDatetime from "react-datetime";
import Header from "components/Headers/Header.jsx";
import Footer from "components/Footers/AdminFooter.jsx";
import { textChangeRangeIsUnchanged } from "typescript";
import axios ,{post} from 'axios'; 
import Moment from 'moment';
// mapTypeId={google.maps.MapTypeId.ROADMAP}
// const MapWrapper = withScriptjs(
//   withGoogleMap(props => (
//     <GoogleMap
//       defaultZoom={12}
//       defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
//       defaultOptions={{
//         scrollwheel: false,
//         styles: [
//           {
//             featureType: "administrative",
//             elementType: "labels.text.fill",
//             stylers: [{ color: "#444444" }]
//           },
//           {
//             featureType: "landscape",
//             elementType: "all",
//             stylers: [{ color: "#f2f2f2" }]
//           },
//           {
//             featureType: "poi",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "road",
//             elementType: "all",
//             stylers: [{ saturation: -100 }, { lightness: 45 }]
//           },
//           {
//             featureType: "road.highway",
//             elementType: "all",
//             stylers: [{ visibility: "simplified" }]
//           },
//           {
//             featureType: "road.arterial",
//             elementType: "labels.icon",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "transit",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "water",
//             elementType: "all",
//             stylers: [{ color: "#5e72e4" }, { visibility: "on" }]
//           }
//         ]
//       }}
//     >
//       <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
//     </GoogleMap>
//   ))
// );

class registerincorporation2 extends React.Component {

  constructor(props){
    super(props);
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
    }
  }

  state = {
    completelist:[],
    rotpendinglist:[],
    tabs:1,
    companyname1:"",
    companyname2:"",
    companyname3:"",
    numberofparticulars:0,
    residentialaddress1:"",
    residentialaddress2:'',
    residentialaddress3:'',
    icorpassport1:'',
    nameparticular1:'',
    contactnumber1:'',
    email1:'',
    shareholding1:0,
    businessaddress1:"",
    businessaddress2:"",
    businessaddress3:"",
    linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"http://crm.mewahtrans.com.my/api/img/profile_img/",
  };  

  componentDidMount(){
   // this.getpendingorderlistapi("Import");
   document.addEventListener('keyup', (e) => {
    if (e.keyCode === 27){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.cancelledsearchbuttonclick()
      }
     
    }else if(e.keyCode === 13){
      e.preventDefault();
      if(window.location.pathname.includes('orders')){
        this.searchfunctionstart()
      }
      
    }
    },false);
  // this.setdatedefaultincomplete();
   // this.getcreatedbywho();
   // this.getorderlistapi("Import");
    this.setState({tabsimportexport:2})
    this.setState({attachmentsetID:Moment().unix()}) ;   
  }

  renderdashboardregister() {
    return (
    
               
      <tbody>
        
       <tr>

       <td>1)</td>
        <td >Company Details</td>
      
        <td >
        <Badge color="" className="badge-dot mr-4">
                                 
                                  <i className="bg-success" />                               
                                
                                Completed
                      </Badge></td>

                      </tr>
                      <tr>   
        <td>2)</td>
        <td >Particulars</td>
        <td >
        <Badge color="" className="badge-dot mr-4">
                                 
                                  <i className="bg-success" />                               
                                
                                Completed
                      </Badge></td>
                      </tr>

<tr>   
        <td>3)</td>
        <td >Shareholders / Members</td>
        <td >
        <Badge color="" className="badge-dot mr-4">
                                 
                                  <i className="bg-info" />                               
                                
                                To be completed
                      </Badge></td>
                        </tr>
                        <tr>   
        <td>4)</td>
        <td >Others information required</td>
        <td >
        <Badge color="" className="badge-dot mr-4">
                                 
                                  <i className="bg-info" />                               
                                
                                To be completed
                      </Badge></td>
                        </tr>
                        <tr>   
        <td>5)</td>
        <td >Others Services</td>
        <td >
        <Badge color="" className="badge-dot mr-4">
                                 
                                  <i className="bg-info" />                               
                                
                                To be completed
                      </Badge></td>
            </tr>    
           
         
          </tbody>

 
 

 )
    // return this.state.rotpendinglist.map((data, index) => {
    //    const {createdby,createdbywhoref,importexport, no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
    //    var rotlength = Object.keys(this.state.rotpendinglist).length;
    //    var volumeshow = totalvolume.slice(0,35)
    //    return (
    
            
    //         <tbody>
              
    //          <tr>
   
    //          <td>1)</td>
    //           <td >Company Details</td>
    //           <td >Completed</td>

    //           <td>2)</td>
    //           <td >Particulars</td>
    //           <td >Completed</td>

    //           <td>3)</td>
    //           <td >Shareholders / Members</td>
    //           <td >Draft</td>

    //           <td>4)</td>
    //           <td >Others information required</td>
    //           <td >Draft</td>

    //           <td>5)</td>
    //           <td >Others Services</td>
    //           <td >Completed</td>
    //               </tr>    
                
    //             </tbody>

       
       
   
    //    )
    // })
  }

  rendercompanydetail(){              
          return (        
            <div className="modal-body">
            <Form>
            {this.state.errorsubmit == true ?
                               <Alert color="danger">
                               {this.state.alerterrormessage}
                             </Alert>
                             :
                             null
                             }
            <Row>
              <Col md="12">
                <p className="h5">Company Name:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Company Name 1"
                    type="text"
                    value={this.state.companyname1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Company Name 2"
                    type="text"
                    value={this.state.companyname2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Company Name 3"
                    type="text"
                    value={this.state.companyname3}
                  />
                </FormGroup>
              </Col>              
            </Row>

            <Row>
            <Col md="12">
                <p className="h5">Nature of business:</p>
                <FormGroup>
                <Input
                    id="Natureofbusiness"
                    placeholder="Nature of Business 1"
                    className="textcolorblack"
                    type="text"
                    value={this.state.natureofbusiness1}                   
                  />
                   </FormGroup>
                   <FormGroup>
                    <Input
                    id="Natureofbusiness2"
                    placeholder="Nature of Business 2"
                    className="textcolorblack"
                    type="text"
                    value={this.state.natureofbusiness2}                   
                  />
                   </FormGroup>
                   <FormGroup>
                    <Input
                    id="Natureofbusines3s"
                    placeholder="Nature of Business 3"
                    className="textcolorblack"
                    type="text"
                    value={this.state.natureofbusiness3}                   
                  />
                   </FormGroup>
               
              </Col>
             
            </Row>

            <Row>
            <Col md="12">
                <p className="h5">Address:</p>
                <FormGroup>
                <Input
                    id="addressline1"
                    placeholder="Address line 1"
                    className="textcolorblack"
                    type="text"
                    value={this.state.addressline1}                   
                  />
                   </FormGroup>
                   <FormGroup>
                    <Input
                    id="addressline2"
                    placeholder="Address line 2"
                    className="textcolorblack"
                    type="text"
                    value={this.state.addressline2}                   
                  />
                   </FormGroup>
                   <FormGroup>
                    <Input
                    id="addressline3"
                    placeholder="Address line 3"
                    className="textcolorblack"
                    type="text"
                    value={this.state.addresslin3}                   
                  />
                   </FormGroup>
               
              </Col>
             
            </Row>        
          
          </Form>
         
       
            </div>
       
        )   
  }

  renderparticularsection(){              
    return (        
      <div className="modal-body">
      <Form>
      {this.state.errorsubmit == true ?
                         <Alert color="danger">
                         {this.state.alerterrormessage}
                       </Alert>
                       :
                       null
                       }
  
      {this.renderparticulargroupdetail()}

    
    </Form>
 
      </div>
 
  )   
  }

  renderparticulargroupdetail(){
    return (        
      <>
      
      <Card className="card-stats mb-4 mb-lg-0 bg-grey">
      <CardBody>
      <p className="h3">Particular 1:</p>

      <Form>
    
      {/* Name */}
      <Row>
        <Col md="12">
          <p className="h5">Name:</p>
          <FormGroup>
          <Input
              id="nameparticular1"
              placeholder="Name"
              value={this.state.nameparticular1} 
              className="textcolorblack"
              // onChange={e => this.setState({gp20pgu: e.target.value})}
            />
      </FormGroup> 
        </Col>              
      </Row>

      {/* Ic number */}
      <Row>
      <Col md="12">
          <p className="h5">NRIC No./Passport No.:</p>
          <FormGroup>
          <Input
              id="icorpassport"
              placeholder="NRIC No./Passport No."
              className="textcolorblack"
              type="text"
              value={this.state.icorpassport1}                   
            />
             </FormGroup>           
         
        </Col>
       
      </Row>

      {/* Contactno */}
      <Row>
      <Col md="12">
          <p className="h5">Contact No:</p>
          <FormGroup>
          <Input
              id="contactnumber"
              placeholder="Contact No."
              className="textcolorblack"
              type="text"
              value={this.state.contactnumber1}                   
            />
             </FormGroup>           
         
        </Col>
       
      </Row>

      {/* Email */}
      <Row>
      <Col md="12">
          <p className="h5">Email:</p>
          <FormGroup>
          <Input
              id="Email"
              placeholder="Email"
              className="textcolorblack"
              type="text"
              value={this.state.email1}           
            />
             </FormGroup>           
         
        </Col>
       
      </Row>
     
     {/* Residential Address */}
      <Row>
      <Col md="12">
          <p className="h5">Residential Address:</p>
          <FormGroup>
          <Input
              id="residentialaddress1"
              placeholder="Address line 1"
              className="textcolorblack"
              type="text"
              value={this.state.residentialaddress1}                   
            />
              <Input
              id="residentialaddress2"
              placeholder="Address line 2"
              className="textcolorblack"
              type="text"
              value={this.state.residentialaddress2}                   
            />
              <Input
              id="residentialaddress3"
              placeholder="Address line 3"
              className="textcolorblack"
              type="text"
              value={this.state.residentialaddress3}                   
            />
             </FormGroup>           
         
        </Col>
       
      </Row>

      {/* Shareholding */}
      <Row>
      <Col md="12">
          <p className="h5">Shareholding:</p>
          <FormGroup>
                            <Input
                                id="shareholding1"
                                placeholder="Shareholding"
                                type="number"
                                min="0"
                                value={this.state.shareholding1} 
                                className="textcolorblack"
                                // onChange={e => this.setState({gp20pgu: e.target.value})}
                              />
                        </FormGroup>            
         
        </Col>
       
      </Row>
      
      {/* Shareholder */}
      <Row>
      <Col md="12">
          <p className="h5">Shareholder:</p>
        <div className="custom-control1 custom-radio1 mb-3">
          <input
            className="custom-control1-input"
            defaultChecked
            id="customRadio1"
            name="custom-radio1-1"
            type="radio"
          />
          <label className="custom-control1-label" htmlFor="customRadio1">
            Yes
          </label>
        </div> 
        <div className="custom-control1 custom-radio1 mb-3">
          <input
            className="custom-control1-input"            
            id="customRadio2"
            name="custom-radio1-1"
            type="radio"
          />
          <label className="custom-control1-label" htmlFor="customRadio2">
            No
          </label>
        </div>
         
        </Col>
       
      </Row>
    
    
     {/* Director */}
      <Row>
      <Col md="12">
          <p className="h5">Director:</p>
        <div className="custom-control3 custom-radio3 mb-3">
          <input
            className="custom-control3-input"
            defaultChecked
            id="customRadio3"
            name="custom-radio-3"
            type="radio"
          />
          <label className="custom-control3-label" htmlFor="customRadio3">
            Yes
          </label>
        </div> 
        <div className="custom-control3 custom-radio3 mb-3">
          <input
            className="custom-control3-input"            
            id="customRadio4"
            name="custom-radio-3"
            type="radio"
          />
          <label className="custom-control3-label" htmlFor="customRadio4">
            No
          </label>
        </div>
         
        </Col>
       
      </Row>    

      {/* Type of Beneficial Owner */}
      <Row>
      <Col md="12">
          <p className="h5">Type of Beneficial Owner (BO):</p>
        <div className="custom-control5 custom-radio5 mb-3">
          <input
            className="custom-control5-input"
            defaultChecked
            id="customRadio5"
            name="custom-radio5-5"
            type="radio"
          />
          <label className="custom-control5-label" htmlFor="customRadio5">
            Direct
          </label>
        </div> 
        <div className="custom-control5 custom-radio5 mb-3">
          <input
            className="custom-control5-input"            
            id="customRadio6"
            name="custom-radio5-5"
            type="radio"
          />
          <label className="custom-control5-label" htmlFor="customRadio6">
          Indirect
          </label>
        </div>
         
        </Col>
       
      </Row>
    

      {/* Criteria of Beneficial Owner */}
      <Row>
      <Col md="12">
          <p className="h5">Criteria of Beneficial Owner (BO):</p>
        <div className="custom-control7 custom-radio5 mb-1">
          <input
            className="custom-control7-input"
            defaultChecked
            id="customRadio7"
            name="custom-radio7-5"
            type="radio"
          />
          <label className="custom-control7-label" htmlFor="customRadio7">
            Has interest, direct or indirectly, in not less than 20% of the shares;
          </label>
        </div> 
        <div className="custom-control7 custom-radio5 mb-1">
          <input
            className="custom-control7-input"            
            id="customRadio8"
            name="custom-radio7-5"
            type="radio"
          />
          <label className="custom-control5-label" htmlFor="customRadio8">
          Hold, directly or indirectly, not less than 20% of the voting shares of the company;
          </label>        
        </div>
        <div className="custom-control7 custom-radio5 mb-1">
          <input
            className="custom-control7-input"            
            id="customRadio9"
            name="custom-radio7-5"
            type="radio"
          />
          <label className="custom-control7-label" htmlFor="customRadio9">
          Has the right to exercise ultimate effective control whether formal or informal over the company; or the directors or the management of company;
          </label>          
          </div>
          <div className="custom-control7 custom-radio5 mb-1">
          <input
            className="custom-control7-input"            
            id="customRadio10"
            name="custom-radio7-5"
            type="radio"
          />
          <label className="custom-control7-label" htmlFor="customRadio10">
          has the right or power to directly or indirectly appoint or remove a director(s) who hold a majority of the voting rights at meeting of directors;
          </label> 
          </div>
          <div className="custom-control7 custom-radio5 mb-1">
          <input
            className="custom-control7-input"            
            id="customRadio11"
            name="custom-radio7-5"
            type="radio"
          />
          <label className="custom-control7-label" htmlFor="customRadio11">
          Is a member of the company and, under an agreement with another member of the company, controls alone a majority of the voting right in the company.
          </label>          
          </div>
        </Col>
       
      </Row>

      {/*indirect reigster form */}
      {this.renderindirectform()}

    
    </Form>
  
        </CardBody>
      </Card>
     
      </>
 
  )   
  }

  renderindirectform(){
    return(
      <>
         <Row>
              <Col md="12">
              <p className="h5">I am/we are holding the voting shares in the Company on behalf of another person as a trustee.</p>
              <p className="h5">The details of the BO are as below:</p>
                <p className="h5">Full Name:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Full Name"
                    type="text"
                    value={this.state.indirectfullname1}
                  />
                </FormGroup>
                <p className="h5">Residential Address:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Residential Address line 1"
                    type="text"
                    value={this.state.indirectresidentialaddressline1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Residential Address line 2"
                    type="text"
                    value={this.state.indirectresidentialaddressline2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Residential Address line 3"
                    type="text"
                    value={this.state.indirectresidentialaddressline3}
                  />
                </FormGroup>
                <p className="h5">Postcode:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Postcode"
                    type="text"
                    value={this.state.indirectpostcode}
                  />
                </FormGroup>
                <p className="h5">Town:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Town"
                    type="text"
                    value={this.state.indirecttown}
                  />
                </FormGroup>
                <p className="h5">State:</p>
                <FormGroup>
                  <Input                  
                    placeholder="State"
                    type="text"
                    value={this.state.indirectstate}
                  />
                </FormGroup>
                <p className="h5">Nationality:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Nationality"
                    type="text"
                    value={this.state.indirectnationality}
                  />
                </FormGroup>
                <p className="h5">Date of birth:</p>
                <FormGroup>
                  <Input                  
                    placeholder="State"
                    type="text"
                    value={this.state.indirectdateofbirth}
                  />
                </FormGroup>
                <p className="h5">NRIC:</p>
                <FormGroup>
                  <Input                  
                    placeholder="NRIC"
                    type="text"
                    value={this.state.indirectNRIC}
                  />
                </FormGroup>
                <p className="h5">Date of becoming a BO:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Date of becoming a BO"
                    type="text"
                    value={this.state.indirectdateofbecomingbo}
                  />
                </FormGroup>
                <p className="h5">Email:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Email"
                    type="text"
                    value={this.state.indirectemail}
                  />
                </FormGroup>
              </Col>              
            </Row>

      </>
    )
  }

  rendershareholdermembertable(){
    return(
      <>
          {/* Shareholder */}
            <Row>
            <Col md="1">   
            <p className="h5">No.</p>          
                <FormGroup>
                <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={1}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={2}
                    disabled
                  />  
                </FormGroup>
                <FormGroup>
                <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={3}
                    disabled
                  />  
                </FormGroup>
                <FormGroup>
                <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={4}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={5}
                    disabled
                  />
                </FormGroup>
                </Col> 
              <Col md="4">           
              <p className="h5">Name of Shareholder/Member</p>          
                <FormGroup>
                  <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={this.state.nameofshareholder1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={this.state.nameofshareholder2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={this.state.nameofshareholder3}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={this.state.nameofshareholder4}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Name of Shareholder/Member"
                    type="text"
                    value={this.state.nameofshareholder5}
                  />
                </FormGroup>
                </Col> 
                <Col md="4"> 
                <p className="h5">IC / Passport / Company No:</p>
                <FormGroup>
                  <Input                  
                    placeholder="IC / Passport / Company No"
                    type="text"
                    value={this.state.icofshareholder1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="IC / Passport / Company No"
                    type="text"
                    value={this.state.icofshareholder2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="IC / Passport / Company No"
                    type="text"
                    value={this.state.icofshareholder3}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="IC / Passport / Company No"
                    type="text"
                    value={this.state.icofshareholder4}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="IC / Passport / Company No"
                    type="text"
                    value={this.state.icofshareholder5}
                  />
                </FormGroup>
                </Col> 
                <Col md="3"> 
                <p className="h5">Number of Share:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Number of Share"
                    type="text"
                    value={this.state.numberofshareshareholder1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Number of Share"
                    type="text"
                    value={this.state.numberofshareshareholder2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Number of Share"
                    type="text"
                    value={this.state.numberofshareshareholder3}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Number of Share"
                    type="text"
                    value={this.state.numberofshareshareholder4}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Number of Share"
                    type="text"
                    value={this.state.numberofshareshareholder5}
                  />
                </FormGroup>
              </Col>              
            </Row>


            {/* Signature */}
            <p className="h5 mt-5">Director 1</p>     
            <Row>              
             <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile3} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload3}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename3 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename3, "_blank")}>
                           Preview
                       </Button> 
                       }
                   </Col>
                    
             </Row>
      </>
    )
  }

  renderotherinformationrequired(){
    return(
      <>
        <p className="h2 mt-5">Other information required:</p> 
        <Row>
              <Col md="12">
                <p className="h5">Business Address:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Business Address 1"
                    type="text"
                    value={this.state.businessaddress1}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Business Address 2"
                    type="text"
                    value={this.state.businessaddress2}
                  />
                </FormGroup>
                <FormGroup>
                  <Input                  
                    placeholder="Business Address 3"
                    type="text"
                    value={this.state.businessaddress3}
                  />
                </FormGroup>
              </Col>              
            </Row>

            <Row>
              <Col md="12">
                <p className="h5">Office Telephone Number:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Office Telephone Number"
                    type="text"
                    value={this.state.officetelephonenumber}
                  />
                </FormGroup>            
              </Col> 
              <Col md="12">
                <p className="h5">Office Email Address:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Office Email Address"
                    type="text"
                    value={this.state.officeemailaddress}
                  />
                </FormGroup>            
              </Col>  
              <Col md="12">
                <p className="h5">Financial year end:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Financial year end:"
                    type="text"
                    value={this.state.financialyearend}
                  />
                </FormGroup>            
              </Col>
              <Col md="12">
                <p className="h5">Bank Account:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Bank Account:"
                    type="text"
                    value={this.state.bankaccount}
                  />
                </FormGroup>            
              </Col>  
              <Col md="12">
                <p className="h5">Bank Authorised Signature:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Bank Authorised Signature:"
                    type="text"
                    value={this.state.bankauthorisesignature}
                  />
                </FormGroup>            
              </Col> 
              <Col md="12">
                <p className="h5">Online Banking:</p>
                <FormGroup>
                  <Input                  
                    placeholder="Online Banking:"
                    type="text"
                    value={this.state.onlinebanking}
                  />
                </FormGroup>            
              </Col>    

            </Row>
      </>
    )
  }

  renderauditor(){
    return(
      <>
           <p className="h2 mt-2">Auditor:</p> 
           <p className="h4">Company ABC</p> 

           <p className="h2 mt-3">Tax Agent:</p> 
           <p className="h4  mb-3">Company ABC</p> 
      </>
    )
  }
  
    
  render() {
    return (
      <>
        <Header />
        {/* Page contentas */}
        <Container className=" mt--10" fluid>
          {/* Table */}
          <Row>
            <div className=" col-12 ">
        
         {/* Space */}
        <Col md="12  mt-7">             
        </Col>


            </div>


            <div className=" col-12 ">
              <Card className=" shadow  ">
              
              
              <Row>
                <Col >

                <div className=" col-12 d-md-inline-block ">
                <div className="nav-wrapper ">
         
            
            
            </div>


                </div>
                
               
                </Col>

                <Col md="8 form-inline  d-md-flex">
             
              
               


                </Col>
              </Row>
             
             
                     
              {this.state.loading
              ?
              <Row >
                <Col md="12" >
              <img 
              className="img-center mt-5"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
              :
              <CardBody >
                
            
                <TabContent  activeTab={"tabs" + this.state.tabs + "andtabsimportexport"+ this.state.tabsimportexport}>
                 
                {/* Dashboard list */}
                  <TabPane tabId="tabs1andtabsimportexport1">
                 
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                 <tr>
                   <th>No.</th>
                   <th >Title</th>
                   <th  >Status</th>    
{/*                  
                   <th >Action</th> */}
                   <th scope="col" />
                 </tr>
               </thead>
                   
                   {this.renderdashboardregister()}       
                   <Button
                    color="primary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.setState({tabsimportexport:2})}
                  >
                    Save and Next
                  </Button>            
                   </Table>
                 
             
                  </TabPane>
                    
                  {/* Company detail */}
                  <TabPane tabId="tabs1andtabsimportexport2">
                  <div className="col">
                   <h3 className="mb-0">Company Details:</h3>
                 </div>

                 {this.rendercompanydetail()}

                  {/* particular section */}
                 <div className="col">
                   <h2 className="mb-4">Particular:</h2>
                
                   <Row>
                          <Col md="12">
                            <p className="h5">Number of Particulars:</p>
                            <FormGroup>
                            <Input
                                id="numberofparticular"
                                placeholder="1"
                                type="number"
                                min="0"
                                value={this.state.numberofparticulars} 
                                className="textcolorblack"
                                onChange={e => this.setState({gp20pgu: e.target.value})}
                              />
                        </FormGroup> 
                          </Col>              
                        </Row>
                 </div>
                 {this.renderparticularsection()}

                   {/* Shareholding section */}
                 <div className="col">
                   <h2 className="mb-4">Shareholders/Members:</h2>
                
                   {this.rendershareholdermembertable()}
                 </div>

                    {/* Other section */}
                 {this.renderotherinformationrequired()}

                   
                    {/* Auditor tax section */}
                   {this.renderauditor()}
                 <Button
                    color="primary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.setState({tabsimportexport:3})}
                  >
                    Save and Next
                  </Button>
         
                  </TabPane>
                    
               
                  

                </TabContent>
              
               
              </CardBody>
                  }

                  
             
                
              </Card>
            </div>           
          </Row>
          
        </Container>
       
      
        
      </>
    );
  }

}



class ComponentToPrint extends React.Component {
  
  state= {  
    
    printbilltoparty:this.props.billingto,
    printbookingno:this.props.Bookingno,
    printclosing:this.props.closingdate,
    printcommodity:this.props.commodity,
    printcontainer1:this.props.container1,
    printcontainer2:this.props.container2,
    printcontainer3:this.props.container3,
    printcontainer4:this.props.container4,
    printcontainer5:this.props.container5,
    printcontainer6:this.props.container6,
    printcontainer7:this.props.container7,
    printcontainer8:this.props.container8,
    printeta:this.props.vesseleta,
    printforwardingagent:this.props.forwardingagent,
    printpic:this.props.pic,
    printpickup:this.props.pickupyard,
    printpod:this.props.pod,
    printpol:this.props.pol,
    printremark:this.props.remark,
    printrequiretime:this.props.containerrequiretime,
    printrequiredate:this.props.containerrequiredate,
    printshipper:this.props.shippername,
    printshippingaddresss:this.props.shipperaddress,
    printshippingliner:this.props.shippingline,
    printsize1:this.props.size1,
    printsize2:this.props.size2,
    printsize3:this.props.size3,
    printsize4:this.props.size4,
    printtype1:this.props.type1,
    printtype2:this.props.type2,
    printtype3:this.props.type3,
    printtype4:this.props.type4,
    printvolume1:this.props.volume1,
    printvolume2:this.props.volume2,
    printvolume3:this.props.volume3,
    printvolume4:this.props.volume4,
    printvoyage:this.props.vesselvoyage,
    printvoyagename:this.props.voyagename,
    printexport:this.props.importexport,
    printcontactpic:this.props.contactpic,
  }

  
  render() {
   // console.log(this.props.importexport);
    return (
      <div class="containerprint">
      <img 
      width="1000px"
      height="1442px"
      alt="..."
      class
      src={require("./../../assets/gif/Rottemplate3.jpg")}
      />
      <span class="importexportprintcss">{this.state.printexport}</span>
      <span class="linerprintcss">{this.state.printbookingno}</span>
       <span class="shippinglinerprintcss">{this.state.printshippingliner}</span>
       <span class="Forwardinglinerprintcss">{this.state.printforwardingagent}</span>
       <span class="Commoditylinerprintcss">{this.state.printcommodity}</span>
       <span class="POLlinerprintcss">{this.state.printpol}</span>
       <span class="PODlinerprintcss">{this.state.printpod}</span>
    <span class="Voyagelinerprintcss">{this.state.printvoyage}/{this.state.printvoyagename}</span>
       <span class="ETAlinerprintcss">{this.state.printeta}</span>
       <span class="Closinglinerprintcss">{this.state.printclosing}</span>
       <span class="Requirelinerprintcss">{this.state.printrequiredate}</span>
       <span class="Requiretimelinerprintcss">{this.state.printrequiretime}</span>
       <span class="Picklinerprintcss">{this.state.printpickup}</span>
       <span class="Shipperprintcss">{this.state.printshipper}</span>
       <span class="PICprintcss">{this.state.printpic}</span>
       <span class="PICcontactcss">{this.state.printcontactpic}</span>
       <span class="shippingaddressprintcss">{this.state.printshippingaddresss}</span>
       <span class="Billprintcss">{this.state.printbilltoparty}</span>
       <span class="volume1printcss">{this.state.printvolume1}</span>
       <span class="volume2printcss">{this.state.printvolume2}</span>
       <span class="volume3printcss">{this.state.printvolume3}</span>
       <span class="volume4printcss">{this.state.printvolume4}</span>
       <span class="size1printcss">{this.state.printsize1}</span>
       <span class="size2printcss">{this.state.printsize2}</span>
       <span class="size3printcss">{this.state.printsize3}</span>
       <span class="size4printcss">{this.state.printsize4}</span>
       <span class="type1printcss">{this.state.printtype1}</span>
       <span class="type2printcss">{this.state.printtype2}</span>
       <span class="type3printcss">{this.state.printtype3}</span>
       <span class="type4printcss">{this.state.printtype4}</span>
       <span class="container1printcss">{this.state.printcontainer1}</span>
       <span class="container2printcss">{this.state.printcontainer2}</span>
       <span class="container3printcss">{this.state.printcontainer3}</span>
       <span class="container4printcss">{this.state.printcontainer4}</span>
       <span class="container5printcss">{this.state.printcontainer5}</span>
       <span class="container6printcss">{this.state.printcontainer6}</span>
       <span class="container7printcss">{this.state.printcontainer7}</span>
       <span class="container8printcss">{this.state.printcontainer8}</span>
       <span class="remarkprintcss">{this.state.printremark}</span>
      </div>
     
       
    );
  }

}

class ComponentToPrint2 extends React.Component {
  
  state= {  
    
    printbilltoparty2:this.props.billingto,
    printbookingno2:this.props.Bookingno,
    printclosing2:this.props.closingdate,
    printcommodity2:this.props.commodity,
    printcontainer12:this.props.container1,
    printcontainer22:this.props.container2,
    printcontainer32:this.props.container3,
    printcontainer42:this.props.container4,
    printcontainer52:this.props.container5,
    printcontainer62:this.props.container6,
    printcontainer72:this.props.container7,
    printcontainer82:this.props.container8,
    printeta2:this.props.vesseleta,
    printforwardingagent2:this.props.forwardingagent,
    printpic2:this.props.pic,
    printpickup2:this.props.pickupyard,
    printpod2:this.props.pod,
    printpol2:this.props.pol,
    printremark2:this.props.remark,
    printrequiretime2:this.props.containerrequiretime,
    printrequiredate2:this.props.containerrequiredate,
    printshipper2:this.props.shippername,
    printshippingaddresss2:this.props.shipperaddress,
    printshippingliner2:this.props.shippingline,
    printsize12:this.props.size1,
    printsize22:this.props.size2,
    printsize32:this.props.size3,
    printsize42:this.props.size4,
    printtype12:this.props.type1,
    printtype22:this.props.type2,
    printtype32:this.props.type3,
    printtype42:this.props.type4,
    printvolume12:this.props.volume1,
    printvolume22:this.props.volume2,
    printvolume32:this.props.volume3,
    printvolume42:this.props.volume4,
    printvoyage2:this.props.vesselvoyage,
    printvesselname:this.props.vesselname,
    printexport2:this.props.importexport,
    printcontactpic2:this.props.contactpic,
  }

  
  render() {
    return (
      <div class="containerprint">
      <img 
      width="1000px"
      height="1442px"
      alt="..."
      class
      src={require("./../../assets/gif/Rottemplate3.jpg")}
      />
      <span class="importexportprintcss">{this.props.importexport}</span>
      <span class="linerprintcss">{this.props.Bookingno}</span>
       <span class="shippinglinerprintcss">{this.props.shippingline}</span>
       <span class="Forwardinglinerprintcss">{this.props.forwardingagent}</span>
       <span class="Commoditylinerprintcss">{this.props.commodity}</span>
       <span class="POLlinerprintcss">{this.props.pol}</span>
       <span class="PODlinerprintcss">{this.props.pod}</span>
       <span class="Voyagelinerprintcss">{this.props.vesselname}/{this.props.vesselvoyage}</span>
       <span class="ETAlinerprintcss">{this.props.vesseleta}</span>
       <span class="Closinglinerprintcss">{this.props.closingdate}</span>
       <span class="Requirelinerprintcss">{this.props.containerrequiredate}</span>
       <span class="Requiretimelinerprintcss">{this.state.printrequiretime2}</span>
       <span class="Picklinerprintcss">{this.props.pickupyard}</span>
       <span class="Shipperprintcss">{this.props.shippername}</span>
       <span class="PICprintcss">{this.props.pic}</span>
       <span class="PICcontactcss">{this.props.contactpic}</span>
       <span class="shippingaddressprintcss">{this.props.shipperaddress}</span>
       <span class="Billprintcss">{this.props.billingto}</span>
       <span class="volume1printcss">{this.props.volume1}</span>
       <span class="volume2printcss">{this.props.volume2}</span>
       <span class="volume3printcss">{this.props.volume3}</span>
       <span class="volume4printcss">{this.props.volume4}</span>
       <span class="size1printcss">{this.state.printsize12}</span>
       <span class="size2printcss">{this.state.printsize22}</span>
       <span class="size3printcss">{this.state.printsize32}</span>
       <span class="size4printcss">{this.state.printsize42}</span>
       <span class="type1printcss">{this.state.printtype12}</span>
       <span class="type2printcss">{this.state.printtype22}</span>
       <span class="type3printcss">{this.state.printtype32}</span>
       <span class="type4printcss">{this.state.printtype42}</span>
       <span class="container1printcss">{this.props.container1}</span>
       <span class="container2printcss">{this.props.container2}</span>
       <span class="container3printcss">{this.props.container3}</span>
       <span class="container4printcss">{this.props.container4}</span>
       <span class="container5printcss">{this.props.container5}</span>
       <span class="container6printcss">{this.props.container6}</span>
       <span class="container7printcss">{this.props.container7}</span>
       <span class="container8printcss">{this.props.container8}</span>
       <span class="remarkprintcss">{this.state.printremark2}</span>
      </div>
     
       
    );
  }
}

export default registerincorporation2;
